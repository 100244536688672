import { Avatar, Badge, Space, Spin, Tabs, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FcFactory } from "react-icons/fc";
import { useParams } from "react-router-dom";
import { ProductionAccess } from "../components/production/access/ProductionAccess";
import { ComponentsView } from "../components/production/components/ComponentsView";
import { ProductionDocuments } from "../components/production/documents/ProductionDocuments";
import ProductionHome from "../components/production/home/ProductionHome";
import { ProductionMetrics } from "../components/production/metrics/ProductionMetrics";
import { withRouter } from "../components/with-router/RouterProps";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import { ProductionsService } from "../services/openapi";
import { setProduction } from "../store/productionSlice";
import { MayBe } from "../components/logic/Maybe";

export const ProductionPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const production = useAppSelector(
        (state) => state.productionState.production
    );

    const [isBusy, setBusy] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("data");
    const { id } = useParams();

    useEffect(() => {
        const loadProduction = async (): Promise<void> => {
            setBusy(true);

            await ProductionsService.getApiProductions1(id!)
                .then((result) => dispatch(setProduction(result)))
                .then(() => setBusy(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        };

        loadProduction();
    }, [id]);

    if (production) {
        return (
            <Spin spinning={isBusy}>
                <Space direction="vertical" style={{ width: "100%" }} size={24}>
                    <Space align="center">
                        <IconContext.Provider
                            value={{ color: "green", size: "2.8em" }}
                        >
                            <Avatar
                                shape="circle"
                                size={48}
                                src={<FcFactory />}
                            />
                        </IconContext.Provider>
                        <Space direction="vertical">
                            <Space>
                                <Typography.Title
                                    style={{
                                        margin: "auto auto",
                                        color: "#1677FF",
                                    }}
                                    level={5}
                                >
                                    {production?.name}
                                </Typography.Title>

                                <MayBe
                                    condition={() =>
                                        production.activationStatus ==
                                        "NotActivated"
                                    }
                                    content={
                                        <Tooltip
                                            color="geekblue"
                                            title="Производство не активировано и не связано со SmartWeb"
                                        >
                                            <Badge color="gray"></Badge>
                                        </Tooltip>
                                    }
                                />

                                <MayBe
                                    condition={() =>
                                        production.activationStatus ==
                                        "Activated"
                                    }
                                    content={
                                        <Tooltip
                                            color="geekblue"
                                            title="Производство успешно активировано. И работает со SmartWeb"
                                        >
                                            <Badge color="green"></Badge>
                                        </Tooltip>
                                    }
                                />

                                <MayBe
                                    condition={() =>
                                        production.activationStatus == "Ready"
                                    }
                                    content={
                                        <Tooltip
                                            color="geekblue"
                                            title="Производство ожидает активации. Введите API ключ на стороне клиента"
                                        >
                                            <Badge
                                                color="yellow"
                                                size="small"
                                            ></Badge>
                                        </Tooltip>
                                    }
                                />

                                {production.licenseKey && (
                                    <Tooltip
                                        color="geekblue"
                                        title="Информация об окончании лицензии"
                                    >
                                        <Typography.Text>
                                            {production.licenseKey.isUnlimited
                                                ? "Безлимитная лицензия"
                                                : new Date(
                                                      production.licenseKey.expireIn!
                                                  ).toLocaleString()}
                                        </Typography.Text>
                                    </Tooltip>
                                )}
                            </Space>

                            <Typography.Text className="description-text">
                                <Space>
                                    <Tooltip
                                        title="Город производства"
                                        color="geekblue"
                                    >
                                        {production?.location?.city}
                                    </Tooltip>
                                    <Tooltip
                                        title="Часовой пояс производства"
                                        color="geekblue"
                                    >
                                        {production.location.timeZone}
                                    </Tooltip>
                                </Space>
                            </Typography.Text>
                        </Space>
                    </Space>

                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        items={[
                            {
                                key: "data",
                                label: "Основное",
                                children: <ProductionHome />,
                            },
                            {
                                key: "access-data",
                                label: "Доступ и лицензии",
                                children: <ProductionAccess />,
                            },
                            {
                                key: "documents",
                                label: "Документы",
                                children: <ProductionDocuments />,
                            },
                            {
                                key: "metrics",
                                label: "Метрики",
                                children: <ProductionMetrics />,
                            },
                            {
                                key: "components",
                                label: "Компоненты",
                                children: <ComponentsView />,
                            },
                        ]}
                    />
                </Space>
            </Spin>
        );
    }
};

export default withRouter(ProductionPage);
