import { Space, Typography } from "antd";
import { ComponentDto, ValueUnitType } from "../../../services/openapi";


export const ComponentInfo: React.FC<{ component: ComponentDto; }> = ({
    component,
}) =>
{
    return (
        <Space direction="vertical" style={ { width: "100%" } }>
            <Typography.Text style={ { color: "var(--color-title-primary)" } }>
                <strong>{ component.name }</strong>
            </Typography.Text>

            { component.currentValue && (
                <>
                    <Typography.Text>
                        Ресурс: { component.currentValue.value } из{ " " }
                        { component.maxValue }
                    </Typography.Text>

                    <Typography.Text>
                        Процент износа:{ " " }
                        { (component.currentValue.value / component.maxValue) *
                            100 }{ " " }
                        %
                    </Typography.Text>
                </>
            ) }

            { component.lastReset && (
                <Typography.Text>
                    Дата последнего сброса:{ " " }
                    { new Date(
                        component.lastReset.resetTime
                    ).toLocaleDateString() }
                </Typography.Text>
            ) }

            { component.metric && (
                <>
                    <Space>
                        <Typography.Text>
                            Оборудование: { component.metric.name }
                        </Typography.Text>

                        <Typography.Text>
                            { component.metric.valueUnitType ==
                                ValueUnitType.COUNTER
                                ? "Счётчик"
                                : "Моточасы" }
                        </Typography.Text>
                    </Space>
                </>
            ) }
        </Space>
    );
};
