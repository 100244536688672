import { RemoteApplicationType } from "../../../../services/openapi";
import { SiAnydesk, SiMicrosoft, SiTeamviewer } from "react-icons/si";
import { CgRemote } from "react-icons/cg";

export const ApplicationIcon: React.FC<{ applicationType: RemoteApplicationType; }> = ({ applicationType }) =>
{
    switch (applicationType)
    {
        case RemoteApplicationType.ANY_DESK: return <SiAnydesk />;
        case RemoteApplicationType.TEAM_VIEWER: return <SiTeamviewer />;
        case RemoteApplicationType.MICROSOFT_REMOTE: return <SiMicrosoft />;
        case RemoteApplicationType.RUST_DESK: return <CgRemote />;
    }
};
