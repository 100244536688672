import React, { ChangeEvent, useState } from "react";
import { FileDto, ProductionDto, ProductionsService, UpdateFileRequest } from "../../../services/openapi";
import { Col, Dropdown, Input, MenuProps, Modal, Space, Typography, message } from "antd";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { BiDotsVerticalRounded } from "react-icons/bi";


export const DocumentActions: React.FC<{ onChange(): Promise<void>; production: ProductionDto; document: FileDto; }> = ({ production, document, onChange }) =>
{
    const [description, setDescription] = useState(document.description ?? '');
    const [name, setName] = useState(document.name!);

    function onFileNameChanged(event: ChangeEvent<HTMLInputElement>): void
    {
        setName(event.target.value);
    }

    function onDescriptionChanged(event: ChangeEvent<HTMLTextAreaElement>): void
    {
        setDescription(event.target.value);
    }

    async function onDeleteClicked(): Promise<void>
    {
        if (!production.id) throw new Error();

        await ProductionsService
            .deleteApiProductionsFiles(
                production.id,
                document.id!)
            .then(async () => await onChange())
            .catch(ErrorHandler.handle);
    }

    async function onSaveClicked(): Promise<void>
    {
        if (!production.id) throw new Error();

        const request: UpdateFileRequest = {
            name: name,
            description: description
        };

        await ProductionsService
            .patchApiProductionsFiles(
                production.id,
                document.id,
                request)
            .then(async () =>
            {
                message.success("Файл изменен");
                await onChange();
            })
            .catch(ErrorHandler.handle)
            .finally(() => setOpen(false));
    }

    const [isOpen, setOpen] = useState<boolean>(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Изменить',
            onClick: () => setOpen(true)
        },
        {
            key: '2',
            danger: true,
            label: 'Удалить',
            onClick: onDeleteClicked
        },
    ];

    return (
        <Col span={ 1 }>
            <Dropdown menu={ { items } }>
                <a onClick={ (e) => e.preventDefault() }>
                    <Space>
                        <BiDotsVerticalRounded />
                    </Space>
                </a>
            </Dropdown>

            <Modal okText="Сохранить" onOk={ onSaveClicked } onCancel={ () => setOpen(false) } cancelText="Отменить" open={ isOpen } title="Редактирование документа">
                <Space direction="vertical" style={ { width: "100%" } } size={ 16 }>
                    <Typography.Text className="description-text">Измените имя файла или описание</Typography.Text>
                    <Input value={ name } onChange={ onFileNameChanged } placeholder="Название файла"></Input>
                    <Input.TextArea placeholder="Описание" value={ description } onChange={ onDescriptionChanged }></Input.TextArea>
                </Space>
            </Modal>

        </Col>
    );
};
