import { List, Row } from "antd";
import { FileDto, ProductionDto } from "../../../services/openapi";
import { DocumentDescription } from "./DocumentDescription";
import { DocumentOwner } from "./DocumentAuthor";
import { DocumentSize } from "./DocumentSize";
import { DocumentEditDate } from "./DocumentEditDate";
import { DocumentActions } from "./DocumentActions";
import { AppConfiguration } from "../../../configurations/AppConfiguration";
import { DocumentAbout } from "./DocumentAbout";

export const DocumentsList: React.FC<{ production: ProductionDto, documents: FileDto[], onChange(): Promise<void> }> = ({ production, documents, onChange }) =>
{
    return (<List
        split
        dataSource={ documents }
        renderItem={ (item) =>
        {
            return (
                <Row align={ "middle" }>
                    <DocumentAbout hrefToDownload={ `${ AppConfiguration.GetBaseAddress() }/api/Productions/${ production.id }/Files/${ item.id }` } document={ item } />
                    <DocumentDescription document={ item } />
                    <DocumentOwner user={ item.owner } />
                    <DocumentEditDate document={ item } />
                    <DocumentSize document={ item } />
                    <DocumentActions document={ item } onChange={ onChange } production={ production } />
                </Row>
            );
        } }>
    </List>)
}

