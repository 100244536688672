/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUnsafeLicenseRequest } from '../models/CreateUnsafeLicenseRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UnsafeLicensesService {

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static postApiUnsafeLicenses(
requestBody?: CreateUnsafeLicenseRequest,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/UnsafeLicenses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
