import { ChangeEvent, Component } from "react";
import { UpdateUserRequest, UsersService } from "../../../services/openapi";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { Button, Form, Input, message } from "antd";
import { IUserContactsProps } from "./IUserContactsProps";
import { IUserContactsState } from "./IUserContactsState";

export class UserContacts extends Component<IUserContactsProps, IUserContactsState>
{
    constructor(props: IUserContactsProps)
    {
        super(props);

        this.state = {
            user: props.user,
        };
    }

    private onFullNameChanged = (event: ChangeEvent<HTMLInputElement>) => this.setState(prevState => ({
        user: {
            ...prevState.user,
            fullname: event.target.value
        }
    }));

    private onPhoneChanged = (event: ChangeEvent<HTMLInputElement>) => this.setState(prevState => ({
        user: {
            ...prevState.user,
            phoneNumber: event.target.value
        }
    }));

    private updateUser = async () =>
    {
        const request: UpdateUserRequest = {
            phoneNumber: this.state.user.phoneNumber!,
            fullname: this.state.user.fullname
        };

        await UsersService
            .putApiUsers(this.state.user.id!, request)
            .then(() => message.success('Информация о пользователе успешно обновлена!'))
            .catch(ErrorHandler.handle);
    };

    render()
    {
        return (
            <Form>
                <Form.Item>
                    <Input value={ this.state.user.email } disabled={ true } readOnly={ true } placeholder="Почтовый адрес"></Input>
                </Form.Item>
                <Form.Item>
                    <Input value={ this.state.user.fullname } onChange={ this.onFullNameChanged } placeholder="ФИО"></Input>
                </Form.Item>
                <Form.Item>
                    <Input value={ this.state.user.phoneNumber! } onChange={ this.onPhoneChanged } placeholder="Номер телефона"></Input>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={ this.updateUser }>Сохранить</Button>
                </Form.Item>
            </Form>
        );
    }
}
