class Locations {
  static Default: string = "/";

  static CreateUser: string = "/users/create";
  static CreateProduction: string = "/productions/create";

  static Users: string = "/users";
  static Productions: string = "/productions";
  static Licenses: string = "/licenses";
  static Login: string = "/login";
  static User: string = "/users/:id";
  static Production: string = "/productions/:id";
}

export default Locations;
