import { Button, Input, Space, Spin, message } from "antd";
import { ChangeEvent, useState } from "react";
import { AddProductionContactsRequest, ProductionsService } from "../../../../services/openapi";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { setProduction } from "../../../../store/productionSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";


export const ProductionContactsEdit: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const [post, setPost] = useState<string>('');
    const [contact, setContact] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [fullname, setFullname] = useState<string>('');
    const [isBusy, setBusy] = useState<boolean>(false);

    const onPostChanged = (event: ChangeEvent<HTMLInputElement>) => setPost(event.target.value);
    const onCommentChanged = (event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value);
    const onFullnameChanged = (event: ChangeEvent<HTMLInputElement>) => setFullname(event.target.value);
    const onContactChanged = (event: ChangeEvent<HTMLInputElement>) => setContact(event.target.value);

    const updateProduction = async () =>
    {
        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onSaveClicked = async () =>
    {
        const request: AddProductionContactsRequest = {
            name: fullname,
            post: post,
            phone: contact,
            comment: comment
        };

        setBusy(true);

        await ProductionsService
            .postApiProductionsContacts(production.id, request)
            .then(async () => await updateProduction())
            .then(() => setBusy(false))
            .then(() => onClick())
            .then(() => message.success("Контакт успешно добавлен"))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    return <Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: "100%" } }>
            <Input value={ fullname } onChange={ onFullnameChanged } placeholder="Фамилия Имя Отчество"></Input>
            <Input value={ post } onChange={ onPostChanged } placeholder="Должность"></Input>
            <Input value={ contact } onChange={ onContactChanged } placeholder="Номер телефона / Почтовый адрес"></Input>
            <Input.TextArea value={ comment } onChange={ onCommentChanged } autoSize={ { minRows: 4, maxRows: 6 } } placeholder="Комментарий"></Input.TextArea>

            <Space>
                <Button type="dashed" onClick={ onClick }>Отменить</Button>
                <Button type="primary" onClick={ onSaveClicked }>Добавить</Button>
            </Space>
        </Space>
    </Spin>;
};
