/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationType {
    SMART_MIX_LABORANT = 'SmartMixLaborant',
    SMART_MIX_DISPATCHER = 'SmartMixDispatcher',
    SMART_MIX_REPORTER = 'SmartMixReporter',
    SMART_WEIGHT = 'SmartWeight',
}
