import React from "react";
import { FolderDto } from "../../../services/openapi";
import { Col, Typography } from "antd";


export const FolderEditDate: React.FC<{ folder: FolderDto; }> = ({ folder }) =>
{
    return (
        <Col span={ 6 } style={ { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" } }>
            { folder.editedAt &&
                <Typography.Text className="description-text">
                    { `${ new Date(folder.editedAt).toLocaleString() }` }
                </Typography.Text> }
        </Col>
    );
};
