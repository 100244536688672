import { Divider, Space, Typography } from "antd";
import React, { useState } from "react";
import { ProductionControllerEdit } from "./ProductionControllerEdit";
import { ProductionControllerInfo } from "./ProductionControllerInfo";

export const ProductionController: React.FC = () =>
{
    const [isEdit, setEdit] = useState<boolean>(false);

    return (
        <Space direction="vertical" style={ { width: "100%" } }>
            <Typography.Title level={ 5 }>
                Информация о контроллере
            </Typography.Title>
            <Typography.Text className="description-text">
                Здесь вы можете посмотреть или изменить информацию о контроллере на стороне клиента.
            </Typography.Text>
            <Divider />

            { isEdit && <ProductionControllerEdit onClick={ () => setEdit(false) } /> }
            { !isEdit && <ProductionControllerInfo onClick={ () => setEdit(true) } /> }
        </Space>);
}