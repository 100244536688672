import { Button, Input, Select, Space, Spin } from "antd";
import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { AddApplicationSettingsRequest, ApplicationType, ProductionsService } from "../../../../services/openapi";
import { DefaultOptionType } from "antd/es/select";
import { setProduction } from "../../../../store/productionSlice";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";


export const ProductionApplicationsEdit: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const applicationOptions: DefaultOptionType[] = [
        { value: ApplicationType.SMART_MIX_DISPATCHER, label: ApplicationType.SMART_MIX_DISPATCHER },
        { value: ApplicationType.SMART_MIX_LABORANT, label: ApplicationType.SMART_MIX_LABORANT },
        { value: ApplicationType.SMART_MIX_REPORTER, label: ApplicationType.SMART_MIX_REPORTER },
        { value: ApplicationType.SMART_WEIGHT, label: ApplicationType.SMART_WEIGHT }
    ];

    const [isBusy, setBusy] = useState<boolean>(false);
    const [address, setAddress] = useState<string>('');
    const [machine, setMachine] = useState<string>('');
    const [identificator, setIdentificator] = useState<string>('');
    const [applicationType, setApplicationType] = useState<ApplicationType>(ApplicationType.SMART_MIX_DISPATCHER);

    const onIdentificatorChanged = (event: ChangeEvent<HTMLInputElement>) => setIdentificator(event.target.value);
    const onAddressChanged = (event: ChangeEvent<HTMLInputElement>) => setAddress(event.target.value);
    const onMachineChanged = (event: ChangeEvent<HTMLInputElement>) => setMachine(event.target.value);

    const updateProduction = async () =>
    {
        if (!production.id) throw new Error();

        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onSaveClicked = async () =>
    {
        if (!production.id) throw new Error();

        const request: AddApplicationSettingsRequest = {
            ipAddress: address,
            machineName: machine,
            pcIdentificator: identificator,
            applicationType: applicationType
        };

        setBusy(true);

        await ProductionsService
            .postApiProductionsApplicationSettings(production.id, request)
            .then(async () => await updateProduction())
            .then(() => onClick())
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    return <Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
            <Select style={ { width: "100%" } } options={ applicationOptions } value={ applicationType } onChange={ setApplicationType }></Select>
            <Input required value={ identificator } onChange={ onIdentificatorChanged } placeholder="Идентификатор компьютера"></Input>
            <Input required value={ machine } onChange={ onMachineChanged } placeholder="Имя компьютера"></Input>
            <Input required value={ address } onChange={ onAddressChanged } placeholder="IP адрес"></Input>

            <Space>
                <Button type="dashed" onClick={ onClick }>Отменить</Button>
                <Button type="primary" onClick={ onSaveClicked }>Добавить</Button>
            </Space>
        </Space>
    </Spin>;
};
