import { Divider, Space, Typography } from "antd";
import { useState } from "react";
import { ProductionApplicationsInfo } from "./ProductionApplicationsInfo";
import { ProductionApplicationsEdit } from "./ProductionApplicationsEdit";

export const ProductionApplications: React.FC = () =>
{
    const [isEdit, setEdit] = useState<boolean>(false);

    return (<Space direction="vertical" style={ { width: "100%" } }>
        <Typography.Title level={ 5 }>
            Программное обеспечение на производстве
        </Typography.Title>

        <Typography.Text className="description-text">
            Здесь вы можете посмотреть или изменить информацию о ПО на производстве.
        </Typography.Text>

        <Divider />

        {
            !isEdit ?
                <ProductionApplicationsInfo onClick={ () => setEdit(true) } /> :
                <ProductionApplicationsEdit onClick={ () => setEdit(false) } />
        }
    </Space >);
}