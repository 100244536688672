import { Button, Input, Space, Spin, TreeSelect, message } from "antd";
import Select, { DefaultOptionType } from "antd/es/select";
import { Guid } from "js-guid";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hook";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import {
    ComponentsService,
    CreateComponentRequest,
    Equipment,
    MetricsService,
    ValueUnitType,
} from "../../../services/openapi";

export interface MetricOptionType extends DefaultOptionType {
    id?: string;
}

export const ComponentCreateView: React.FC<{
    onClose(): void;
    onChange(): Promise<void>;
}> = ({ onClose, onChange }) => {
    const production = useAppSelector(
        (state) => state.productionState.production!
    );

    const [isBusy, setBusy] = useState<boolean>(false);
    const [maxValue, setMaxValue] = useState<number>();
    const [componentName, setComponentName] = useState<string>();
    const [selectedMetric, setSelectedMetric] = useState<MetricOptionType>();
    const [equipmentsOptions, setEquipmentsOptions] = useState<
        MetricOptionType[]
    >([]);
    const [componentsOptions, setComponentsOptions] = useState<
        DefaultOptionType[]
    >([]);

    const onMetricChange = (metricOption: MetricOptionType) =>
        setSelectedMetric(metricOption);
    const onComponentNameChanged = (event: string) => setComponentName(event);
    const onMaxValueChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setMaxValue(Number.parseFloat(event.target.value));
    const translateUnitType = (valueUnitType: ValueUnitType) =>
        valueUnitType == ValueUnitType.COUNTER ? "Счётчик" : "Моточасы";

    const mapEquipments = (equipments: Equipment[]): MetricOptionType[] => {
        return equipments?.map<MetricOptionType>((equipment) => ({
            value: [
                equipment.id,
                Guid.newGuid().toString(),
                equipment.name,
            ].join("_"),
            label: `${equipment.name} Номер: ${equipment.objectId}`,
            children: equipment.equipments
                ?.map<MetricOptionType>((includedEquipment) => ({
                    value: [
                        includedEquipment.id,
                        Guid.newGuid().toString(),
                        equipment.name,
                    ].join("_"),
                    label: includedEquipment.name!,
                    children: includedEquipment.metrics?.map((metric) => ({
                        value: [
                            metric.id,
                            Guid.newGuid().toString(),
                            equipment.name,
                            translateUnitType(metric.valueUnitType),
                        ].join("_"),
                        label: `${metric.name} ${translateUnitType(
                            metric.valueUnitType
                        )}`,
                    })),
                }))
                .concat(
                    equipment.metrics?.map<MetricOptionType>((metric) => ({
                        value: [
                            metric.id,
                            Guid.newGuid().toString(),
                            equipment.name,
                            translateUnitType(metric.valueUnitType),
                        ].join("_"),
                        label: `${metric.name} ${translateUnitType(
                            metric.valueUnitType
                        )}`,
                    })) ?? []
                ),
        }));
    };

    const onCreateClick = async () => {
        const request: CreateComponentRequest = {
            maxValue: maxValue ?? 0,
            name: componentName!,
            metricId: (selectedMetric!.value as string).split("_")[0],
        };

        console.log(request);
        console.log(selectedMetric);

        setBusy(true);

        await ComponentsService.postApiComponents(request)
            .then(() => setBusy(false))
            .then(() => onClose())
            .then(async () => await onChange())
            .then(() => message.success("Компонент успешно создан"))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    useEffect(() => {
        async function loadEquipments() {
            setBusy(true);

            await MetricsService.getApiMetricsEquipments(production.id)
                .then((equipments) =>
                    setEquipmentsOptions(mapEquipments(equipments))
                )
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }

        async function loadComponents() {
            await ComponentsService.getApiComponentsList()
                .then((result) =>
                    setComponentsOptions(
                        result.map<DefaultOptionType>((item) => ({
                            label: item,
                            value: item,
                        }))
                    )
                )
                .catch(ErrorHandler.handle);
        }

        loadEquipments();
        loadComponents();
    }, [production.id]);

    return (
        <Spin spinning={isBusy}>
            <Space direction="vertical" style={{ width: "100%" }} size={12}>
                <Select
                    style={{ width: "100%" }}
                    placeholder="Выберите название компонента"
                    onChange={onComponentNameChanged}
                    value={componentName}
                    options={componentsOptions}
                ></Select>
                <Input
                    onChange={onMaxValueChanged}
                    value={maxValue}
                    type="number"
                    placeholder="Максимальное значение"
                ></Input>

                <TreeSelect
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Метрика"
                    allowClear
                    treeDefaultExpandAll
                    labelInValue
                    showSearch
                    onChange={onMetricChange}
                    treeData={equipmentsOptions}
                />

                <Space>
                    <Button onClick={onClose} type="dashed">
                        Назад
                    </Button>
                    <Button onClick={onCreateClick} type="primary">
                        Создать компонент
                    </Button>
                </Space>
            </Space>
        </Spin>
    );
};

export default ComponentCreateView;
