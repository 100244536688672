import { Avatar, Button, Card, Col, Divider, Empty, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import { useState } from "react";
import { ProductionContactDto, ProductionsService } from "../../../../services/openapi";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { setProduction } from "../../../../store/productionSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { AiOutlineDelete } from "react-icons/ai";


export const ProductionContactsInfo: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const [isBusy, setBusy] = useState<boolean>(false);

    const updateProduction = async () =>
    {
        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onDeleteClicked = async (contact: ProductionContactDto) =>
    {
        setBusy(true);

        await ProductionsService
            .deleteApiProductionsContacts(production.id, contact.id)
            .then(async () => await updateProduction())
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    return (<Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
            { Object.keys(production.contacts ?? {}).length != 0 ?
                <Space direction="vertical" style={ { width: "100%" } }>
                    <Row>
                        { production.contacts?.map((contact, index) =>
                        {
                            return <Col key={ contact.id } span={ 24 }>
                                <Card title={ <Space>
                                    <Avatar size={ 24 } src={ `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${ index }` } />
                                    <Divider type="vertical" />
                                    <Typography.Title style={ { margin: "auto auto", color: "#1677FF" } } level={ 5 }>
                                        { contact?.name }
                                    </Typography.Title>
                                </Space> } extra={ <Tooltip title="Удалить контакт" color="geekblue">
                                    <Popconfirm okText="Да, удалить" onConfirm={ () => onDeleteClicked(contact) } cancelText="Нет" title="Вы уверены что хотите удалить контакт производства?">
                                        <Button type="link" danger><AiOutlineDelete /></Button>
                                    </Popconfirm>
                                </Tooltip> } style={ { width: "100%" } }>
                                    <Space align="center" style={ { width: "100%" } }>
                                        <Space direction="vertical" style={ { width: "100%" } }>
                                            <Row>
                                                <Col span={ 24 }>
                                                    <Typography.Text className="description-text">
                                                        Телефон: { contact?.phone }
                                                    </Typography.Text>

                                                    <Divider type="vertical" />

                                                    <Typography.Text className="description-text">
                                                        Должность: { contact?.post }
                                                    </Typography.Text>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={ 24 }>
                                                    <Typography.Text className="description-text">
                                                        { contact?.comment }
                                                    </Typography.Text>
                                                </Col>
                                            </Row>
                                        </Space>
                                    </Space>

                                </Card>
                            </Col>;
                        }) }
                    </Row>
                    <Button onClick={ onClick } type="primary">Добавить контакт</Button>
                </Space>
                :
                <Empty description="Ещё нет данных о контактах">
                    <Button onClick={ onClick } type="primary">Добавить контакт</Button>
                </Empty> }
        </Space>
    </Spin>);
};
