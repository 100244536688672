/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Equipment } from "../models/Equipment";
import type { MetricPagedList } from "../models/MetricPagedList";
import type { MetricsReport } from "../models/MetricsReport";
import type { MetricValue } from "../models/MetricValue";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class MetricsService {
    /**
     * @param productionId
     * @returns MetricsReport Success
     * @throws ApiError
     */
    public static getApiMetricsReport(
        productionId?: string
    ): CancelablePromise<MetricsReport> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Metrics/Report",
            query: {
                productionId: productionId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param productionId
     * @param query
     * @param offset
     * @param quantity
     * @returns MetricPagedList Success
     * @throws ApiError
     */
    public static getApiMetricsSearch(
        productionId?: string,
        query?: string,
        offset?: number,
        quantity: number = 10
    ): CancelablePromise<MetricPagedList> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Metrics/Search",
            query: {
                productionId: productionId,
                query: query,
                offset: offset,
                quantity: quantity,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param productionId
     * @returns Equipment Success
     * @throws ApiError
     */
    public static getApiMetricsEquipments(
        productionId?: string
    ): CancelablePromise<Array<Equipment>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Metrics/AllEquipments",
            query: {
                productionId: productionId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param productionId
     * @returns Equipment Success
     * @throws ApiError
     */
    public static getApiMetricsTopEquipments(
        productionId?: string
    ): CancelablePromise<Array<Equipment>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Metrics/TopEquipments",
            query: {
                productionId: productionId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param metricId
     * @param offset
     * @param quantity
     * @returns MetricValue Success
     * @throws ApiError
     */
    public static getApiMetricsValues(
        metricId?: string,
        fromDate?: string,
        toDate?: string
    ): CancelablePromise<Array<MetricValue>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Metrics/Values",
            query: {
                metricId: metricId,
                fromDate: fromDate,
                toDate: toDate,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
