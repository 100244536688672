import { AiOutlineFile } from "react-icons/ai";
import { BsFileEarmarkPdf, BsFileEarmarkZip, BsFileTextFill, BsFiletypeDocx, BsFiletypeExe, BsFiletypeJson, BsFiletypeMp4, BsImageAlt } from "react-icons/bs";
import { FaFileVideo } from "react-icons/fa";

export class DocumentsProvider
{
    static getIcon = (fileName: string): JSX.Element =>
    {
        const extension = this.getExtension(fileName);

        switch (extension)
        {
            case "bmp":
            case "png":
            case "jpg": return <BsImageAlt />;

            case "txt": return <BsFileTextFill />;
            case "docx": return <BsFiletypeDocx />;
            case "pdf": return <BsFileEarmarkPdf />;
            case "json": return <BsFiletypeJson />;

            case "zip":
            case "gz":
            case "tar": return <BsFileEarmarkZip />;

            case "mov": return <FaFileVideo />;
            case "mp4": return <BsFiletypeMp4 />;
            case "exe": return <BsFiletypeExe />;
        }

        return <AiOutlineFile />;
    };

    static isSeparator = (value: string): boolean => value === '/' || value === '\\' || value === ':';

    static getFileNameWithoutExtension = (fileName: string): string =>
        fileName.split(".")[0]

    static getExtension = (path: string): string =>
    {
        for (let i = path.length - 1; i > -1; --i)
        {
            const value = path[i];
            if (value === '.')
            {
                if (i > 1)
                {
                    if (this.isSeparator(path[i - 1]))
                    {
                        return '';
                    }
                    return path.substring(i + 1);
                }
                return '';
            }
            if (this.isSeparator(value))
            {
                return '';
            }
        }
        return '';
    };
}
