import { Button, DatePicker, Divider, Popconfirm, Space, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { ComponentDto, ComponentsService } from "../../../services/openapi";

export const ComponentToolbar: React.FC<{
    component: ComponentDto;
    onClose(): void;
    onEdit(editMode: boolean): void;
    onDelete(): Promise<void>;
    onChange(): Promise<void>;
}> = ({ component, onClose, onDelete, onChange, onEdit }) => {
    const [resetDate, setResetDate] = useState<Dayjs | null | undefined>();

    const deleteComponentHandler = async () => {
        await ComponentsService.deleteApiComponents(component.id)
            .then(async () => await onDelete())
            .then(() => onClose())
            .catch((error) => ErrorHandler.handle(error));
    };

    const componentEditHandler = () => {
        onEdit(true);
    };

    const resetComponentHandler = async () => {
        await ComponentsService.postApiComponentsReset(
            component.id,
            resetDate?.toJSON()
        )
            .then(async () => await onChange())
            .then(() => message.success("Компонент успешно сброшен."))
            .catch((error) => ErrorHandler.handle(error));
    };

    const resetDateHandler = (date: Dayjs | null | undefined) => {
        setResetDate(date);
    };

    return (
        <Space>
            <Button type="dashed" size="small" onClick={onClose}>
                Назад
            </Button>

            <Divider type="vertical" />

            <Button
                type="link"
                size="small"
                onClick={componentEditHandler}
                icon={<BiEdit />}
            />

            <Divider type="vertical" />

            <Popconfirm
                onConfirm={deleteComponentHandler}
                title={"Вы действительно хотите удалить компонент?"}
            >
                <Button
                    type="link"
                    size="small"
                    icon={<BiTrash />}
                    danger
                ></Button>
            </Popconfirm>

            <Divider type="vertical" />

            <DatePicker
                format="DD.MM.YYYY"
                disabledDate={(date) =>
                    date < dayjs(component.firstReportDate) ||
                    date > dayjs(component.lastReportDate)
                }
                value={resetDate}
                onChange={resetDateHandler}
                placeholder="Дата сброса"
                size="small"
            />

            <Popconfirm
                onConfirm={resetComponentHandler}
                title={"Вы действительно хотите сбросить значения компонента?"}
            >
                <Button
                    disabled={resetDate == null}
                    type="dashed"
                    size="small"
                    danger
                >
                    Сбросить значения
                </Button>
            </Popconfirm>
        </Space>
    );
};
