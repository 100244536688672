import { Space, Tabs } from "antd";
import { useState } from "react";
import { AiOutlineCloudServer, AiTwotoneAppstore } from "react-icons/ai";
import { BsPersonWorkspace } from "react-icons/bs";
import { GoComment } from "react-icons/go";
import { TbLogicAnd } from "react-icons/tb";
import { TiContacts } from "react-icons/ti";
import { VscRemoteExplorer } from "react-icons/vsc";
import { ProductionApplications } from "./applications/ProductionApplications";
import { ProductionComment } from "./comment/ProductionComment";
import { ProductionContacts } from "./contacts/ProductionContacts";
import { ProductionController } from "./controller/ProductionController";
import { ProductionEmployees } from "./employees/ProductionEmployees";
import { ProductionRemote } from "./remote/ProductionRemote";
import { ServerSettings } from "./server-settings/ServerSettings";


export const ProductionHome: React.FC = () =>
{
    const [activeTab, setActiveTab] = useState<string>('server')

    const onTabChanged = (activeTab: string) =>
        setActiveTab(activeTab);

    return (
        <Space direction="vertical" style={ { width: "100%" } }>
            <Tabs
                tabPosition="left"
                activeKey={ activeTab }
                onChange={ onTabChanged }
                items={ [
                    // {
                    //     key: 'main',
                    //     label: <Space>
                    //         <FiSettings />
                    //         Основное
                    //     </Space>,
                    //     children: `Content of tab ${ 1 }`
                    // },
                    {
                        key: 'server',
                        label: <Space>
                            <AiOutlineCloudServer />
                            Сервер
                        </Space>,
                        children: <ServerSettings />
                    },
                    {
                        key: 'plc',
                        label: <Space>
                            <TbLogicAnd />
                            ПЛК
                        </Space>,
                        children: <ProductionController />
                    },
                    {
                        key: 'employees',
                        label: <Space>
                            <BsPersonWorkspace />
                            Работники
                        </Space>,
                        children: <ProductionEmployees />
                    },
                    {
                        key: 'contacts',
                        label: <Space>
                            <TiContacts />
                            Контакты
                        </Space>,
                        children: <ProductionContacts />
                    },
                    {
                        key: 'software',
                        label: <Space>
                            <AiTwotoneAppstore />
                            Програмное обеспечение
                        </Space>,
                        children: <ProductionApplications />
                    },
                    {
                        key: 'remote',
                        label: <Space>
                            <VscRemoteExplorer />
                            Удаленный доступ
                        </Space>,
                        children: <ProductionRemote />
                    },
                    {
                        key: 'comment',
                        label: <Space>
                            <GoComment />
                            Комментарий
                        </Space>,
                        children: <ProductionComment />
                    },
                ] }
            />
        </Space >
    );
}

export default ProductionHome;