import { Button, Space, Tooltip, Typography } from "antd"
import { AiOutlineCopy } from "react-icons/ai"
import Copy from "../../services/copy/Copy"

export const CopyArea: React.FC<{ description: string, textToCopy: string }> = ({ description, textToCopy }) => {
    return (<Space>
        <Typography.Text>
            {description} :
        </Typography.Text>
        <Typography.Link>
            {textToCopy}
        </Typography.Link>
        <Tooltip title="Нажмите чтобы скопировать" color="geekblue">
            <Button
                shape="circle"
                onClick={_ => Copy(textToCopy)}
                type="link" icon={<AiOutlineCopy />} />
        </Tooltip>
    </Space>)
}