import { PasswordGenerator } from '@llftr/password-generator';
import { Button, Divider, Form, Modal, Popconfirm, Select, Typography, message } from "antd";
import { Component } from "react";
import Locations from "../../../consts/Locations";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { PasswordResetRequest, UserDto, UsersService } from "../../../services/openapi";

interface IUserAccessProps {
    user: UserDto
}

interface IUserAccessState {
    user: UserDto
    selectedRoles?: string[]
    availableRoles: string[]
}

export class UserAccess extends Component<IUserAccessProps, IUserAccessState>
{
    constructor(props: IUserAccessProps) {
        super(props);

        this.state = {
            user: props.user,
            availableRoles: ["Admin", "Developer", "Seller"]
        };
    }

    private onResetConfirm = () => {
        const generator = new PasswordGenerator();
        const password = generator.generateWithAllAlphabets();
        const request: PasswordResetRequest = {
            password: password
        };

        UsersService
            .postApiUsersResetPassword(this.state.user.id, request)
            .then(() => Modal.success({ title: 'Новый пароль', content: password }))
            .catch(ErrorHandler.handle);
    }

    private onDeleteConfirm = () => {
        UsersService
            .deleteApiUsers(this.state.user.id)
            .then(() => {
                window.location.assign(Locations.Users);
            })
            .catch(ErrorHandler.handle);
    }

    private onUpdateRolesClicked = () => {
        UsersService
            .putApiUsersRoles(this.state.user.id, this.state.selectedRoles)
            .then(() => message.success('Роли пользователя успешно обновлены. Пользователю необходимо перелогиниться.'))
            .catch(ErrorHandler.handle);
    }

    private onRolesChanged = (roles: string[]) =>
        this.setState({ selectedRoles: roles });

    private loadUserRoles = async () => {
        await UsersService
            .getApiUsersRoles(this.state.user.id)
            .then(roles => {
                const selectedRoles = roles.map(
                    role => role.name
                );

                this.setState({ selectedRoles: selectedRoles as string[] });
            })
            .catch(ErrorHandler.handle);
    }

    componentDidMount(): void {
        this.loadUserRoles();
    }

    render() {
        return (
            <Form>
                <Form.Item>
                    <Typography.Title level={5}>
                        Роли пользователя
                    </Typography.Title>
                    <Typography.Text className="description-text">
                        От выбранных ролей пользователя зависит показываемый ему контент плафтормы, и ограничевается доступ к функционалу
                    </Typography.Text>
                </Form.Item>
                <Form.Item>
                    <Select
                        mode="multiple"
                        onChange={this.onRolesChanged}
                        style={{ width: "100%" }}
                        placeholder="Роли пользователя"
                        value={this.state.selectedRoles}
                        options={this.state.availableRoles?.map(item => ({
                            value: item,
                            lable: item
                        }))}></Select>
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.onUpdateRolesClicked} type="primary">Изменить роли</Button>
                </Form.Item>

                <Divider />

                <Form.Item>
                    <Typography.Title level={5}>
                        Пароль
                    </Typography.Title>
                    <Typography.Text className="description-text">
                        Вы можете сбросить пароль пользователю, в случае его утери или компроментации
                    </Typography.Text>
                </Form.Item>

                <Form.Item>
                    <Popconfirm
                        title="Сброс пароля"
                        description="Вы действительно хотите сбросить пароль?"
                        onConfirm={this.onResetConfirm}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button danger type="primary">Сбросить пароль</Button>
                    </Popconfirm>
                </Form.Item>

                <Divider />

                <Form.Item>
                    <Typography.Title level={5}>
                        Удалить аккаунт
                    </Typography.Title>
                    <Typography.Text className="description-text">
                        Вы можете удалить аккаунт, если он больше не требуется
                    </Typography.Text>
                </Form.Item>

                <Form.Item>
                    <Popconfirm
                        title="Удаление пользователя"
                        description="Вы действительно хотите удалить пользователя?"
                        onConfirm={this.onDeleteConfirm}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button danger type="primary">Удалить аккаунт</Button>
                    </Popconfirm>
                </Form.Item>
            </Form>
        );
    }
}