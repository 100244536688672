/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Col,
    Divider,
    Input,
    List,
    Row,
    Space,
    Spin,
    Statistic,
    Typography,
} from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hook";
import {
    ErrorHandler as ErrorBoundary,
    ErrorHandler,
} from "../../../services/errors/ErrorHandler";
import { Equipment, MetricsService } from "../../../services/openapi";
import { MetricsReport } from "../../../services/openapi/models/MetricsReport";
import { EquipmentRenderElement } from "./EquipmentRenderElement";

export type EquipmentsContext = {
    isDetailsVisible: boolean;
    equipment: Equipment;
};

export const ProductionEquipmentsList: React.FC<{
    onClick(equipment: Equipment): void;
}> = ({ onClick }) => {
    const production = useAppSelector(
        (state) => state.productionState.production!
    );
    const [isBusy, setBusy] = useState<boolean>(true);
    const [equipments, setEquipments] = useState<Equipment[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const onSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSearchQuery(event.target.value);

    const getFilteredEquipments = () => {
        return equipments.filter((e) =>
            e.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    useEffect(() => {
        async function loadEquipments() {
            setBusy(true);

            await MetricsService.getApiMetricsTopEquipments(production.id)
                .then(setEquipments)
                .then(() => setBusy(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }

        loadEquipments();
    }, [production.id]);

    return (
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
            <Row>
                <Col xl={4} xs={24}>
                    <Space
                        direction="vertical"
                        size={24}
                        style={{ width: "100%" }}
                    >
                        <Input
                            placeholder="Поиск"
                            onChange={onSearchQueryChange}
                            value={searchQuery}
                        ></Input>
                    </Space>
                </Col>
                <Divider />
                <Col span={24}>
                    <List
                        loading={isBusy}
                        pagination={{ pageSize: 5, pageSizeOptions: [5] }}
                        dataSource={getFilteredEquipments()}
                        renderItem={(item) => (
                            <Row>
                                <Col xl={12} xs={24}>
                                    <Typography.Link
                                        onClick={() => onClick(item)}
                                    >
                                        {item.name}
                                    </Typography.Link>
                                </Col>
                                <Col xl={10} xs={24}>
                                    <Typography.Text className="description-text">
                                        Номер линии: {item.lineNumber}
                                    </Typography.Text>
                                </Col>
                                <Col xl={2} xs={24}>
                                    <Typography.Text className="description-text">
                                        Тип: {item.type}
                                    </Typography.Text>
                                </Col>
                                <Divider></Divider>
                            </Row>
                        )}
                    ></List>
                </Col>
            </Row>
        </Space>
    );
};

export const ProductionMetricsReportStatistic: React.FC<{
    report: MetricsReport;
}> = ({ report }) => {
    return (
        <Row>
            {report.firstDate && (
                <Col span={10} xl={10} xs={24}>
                    <Statistic
                        title="Первый отчёт"
                        value={new Date(report.firstDate).toLocaleString()}
                    ></Statistic>
                </Col>
            )}

            {report.lastDate && (
                <Col span={12} xl={12} xs={24}>
                    <Statistic
                        title="Последний отчёт"
                        value={new Date(report.lastDate).toLocaleString()}
                    ></Statistic>
                </Col>
            )}

            <Col span={2} xl={2} xs={24}>
                <Statistic
                    title="Отчётов"
                    value={report.totalItems}
                ></Statistic>
            </Col>
        </Row>
    );
};

export const ProductionMetrics: React.FC = () => {
    const production = useAppSelector(
        (state) => state.productionState.production!
    );

    const [isBusy, setBusy] = useState<boolean>(false);
    const [report, setReport] = useState<MetricsReport>();
    const [equipment, setEquipment] = useState<Equipment>();

    const onEquipmentClicked = (equipment: Equipment) =>
        setEquipment(equipment);
    const onEquipmentCloseClicked = () => setEquipment(undefined);

    useEffect(() => {
        async function loadReport(): Promise<void> {
            setBusy(true);

            MetricsService.getApiMetricsReport(production.id)
                .then(setReport)
                .then(() => setBusy(false))
                .catch(ErrorBoundary.handle)
                .finally(() => setBusy(false));
        }

        loadReport();
    }, [production.id]);

    if (!equipment) {
        return (
            <Spin spinning={isBusy}>
                <Space direction="vertical" style={{ width: "100%" }} size={24}>
                    {report && (
                        <ProductionMetricsReportStatistic report={report} />
                    )}
                    {report && (
                        <ProductionEquipmentsList
                            onClick={onEquipmentClicked}
                        />
                    )}
                </Space>
            </Spin>
        );
    }

    return (
        <EquipmentRenderElement
            onClose={onEquipmentCloseClicked}
            equipment={equipment}
        />
    );
};
