import { Button, Input, Space, Spin, message } from "antd";
import { ProductionsService, UpdatePLCRequest } from "../../../../services/openapi";
import { ChangeEvent, useEffect, useState } from "react";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setProduction } from "../../../../store/productionSlice";

export const ProductionControllerEdit: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const [isBusy, setBusy] = useState<boolean>(false);
    const [model, setModel] = useState<string>('');
    const [serial, setSerial] = useState<string>('');
    const [ipAddress, setIpAddress] = useState<string>('');
    const [macAddress, setMacAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [projectPassword, setProjectPassword] = useState<string>('');

    const onModelChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setModel(event.target.value);

    const onSerialChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setSerial(event.target.value);

    const onIpAddressChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setIpAddress(event.target.value);

    const onMacAddressChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setMacAddress(event.target.value);

    const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);

    const onProjectPasswordChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setProjectPassword(event.target.value);

    const updateProduction = async () =>
    {
        if (production)
        {
            await ProductionsService
                .getApiProductions1(production.id)
                .then(result => dispatch(setProduction(result)))
                .catch(ErrorHandler.handle);
        }
    }

    const onUpdatePlcClicked = async () =>
    {
        const request: UpdatePLCRequest = {
            model: model,
            ipAddress: ipAddress,
            serialNumber: serial,
            macAddress: macAddress,
            password: password,
            projectPassword: projectPassword,
        };

        setBusy(true);

        await ProductionsService
            .putApiProductionsPlc(production.id, request)
            .then(async () => await updateProduction())
            .then(() => onClick())
            .then(() => message.success("Настройки успешно сохранены"))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    useEffect(() =>
    {
        setIpAddress(production.plc?.ipAddress ?? '');
        setMacAddress(production.plc?.macAddress ?? '');
        setModel(production.plc?.model ?? '');
        setPassword(production.plc?.password ?? '');
        setProjectPassword(production.plc?.projectPassword ?? '');
        setSerial(production.plc?.serialNumber ?? '');
    },
        [production.plc?.ipAddress,
        production.plc?.macAddress,
        production.plc?.model,
        production.plc?.password,
        production.plc?.projectPassword,
        production.plc?.serialNumber]);

    return (
        <Spin spinning={ isBusy }>
            <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
                <Input placeholder="Модель" value={ model } onChange={ onModelChanged }></Input>
                <Input placeholder="Серийный номер" value={ serial } onChange={ onSerialChanged }></Input>
                <Input placeholder="IP адрес" value={ ipAddress } onChange={ onIpAddressChanged }></Input>
                <Input placeholder="MAC адрес" value={ macAddress } onChange={ onMacAddressChanged }></Input>
                <Input placeholder="Пароль" value={ password } onChange={ onPasswordChanged }></Input>
                <Input placeholder="Пароль от проекта" value={ projectPassword } onChange={ onProjectPasswordChanged }></Input>

                <Space>
                    <Button type="dashed" onClick={ onClick }>Отменить</Button>
                    <Button type="primary" onClick={ onUpdatePlcClicked } color="primary">Сохранить</Button>
                </Space>
            </Space>
        </Spin>
    );
};
