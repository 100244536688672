import { Button, Empty, Space } from "antd";
import React from "react";
import { CopyArea } from "../../../copy-area/CopyArea";
import { useAppSelector } from "../../../../hooks/hook";


export const ProductionControllerInfo: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);

    return (
        <Space direction="vertical" style={ { width: "100%" } }>
            { Object.keys(production.plc ?? {}).length > 0 ?

                <Space style={ { width: "100%" } } direction="vertical">
                    { production.plc?.model && <CopyArea description="Модель" textToCopy={ production.plc.model } /> }
                    { production.plc?.serialNumber && <CopyArea description={ "Серийный номер" } textToCopy={ production.plc.serialNumber }></CopyArea> }
                    { production.plc?.ipAddress && <CopyArea description={ "IP адрес" } textToCopy={ production.plc.ipAddress }></CopyArea> }
                    { production.plc?.macAddress && <CopyArea description={ "MAC адрес" } textToCopy={ production.plc.macAddress }></CopyArea> }
                    { production.plc?.password && <CopyArea description={ "Пароль" } textToCopy={ production.plc.password }></CopyArea> }
                    { production.plc?.projectPassword && <CopyArea description={ "Пароль от проекта" } textToCopy={ production.plc.projectPassword }></CopyArea> }
                    <Button type="primary" color="primary" onClick={ onClick }>Изменить</Button>
                </Space> :
                <Empty description="Нет данных по ПЛК">
                    <Button type="primary" onClick={ onClick }>Добавить</Button>
                </Empty> }
        </Space>
    );
};
