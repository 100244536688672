import
    {
        Avatar,
        Button,
        Card,
        Col,
        Form,
        Input,
        Row,
        Select,
        Space,
        Spin,
        Steps,
        Typography
    } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { CiMail, CiPhone, CiUser } from "react-icons/ci";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import { AddUserRequest, RoleDto, RolesService, UsersService } from "../services/openapi";

import Locations from "../consts/Locations";
import "./CreateUserPage.css";

export const CreateUserPage: React.FC = () => {
    const [isBusy, setBusy] = useState<boolean>(false);
    const [roles, setRoles] = useState<RoleDto[]>([]);
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [fullName, setFullName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const onRolesChanged = (roles: string[]) => setSelectedRoles(roles);
    const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const onConfirmPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);
    const onEmailChanged = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const onPhoneChanged = (event: ChangeEvent<HTMLInputElement>) => setPhone(event.target.value);
    const onFullNameChanged = (event: ChangeEvent<HTMLInputElement>) => setFullName(event.target.value);
    const backStep = () => setCurrentStep(currentStep - 1);
    const nextStep = () => setCurrentStep(currentStep + 1);

    const createUser = async () => {
        setBusy(true);

        const request: AddUserRequest = {
            email: email,
            password: password,
            roles: selectedRoles?.map(item => item.toUpperCase()),
            phone: phone,
            fullname: fullName,
            userName: email,
        }

        await UsersService
            .postApiUsers(request)
            .then(() => window.location.assign(Locations.Users))
            .catch(error => ErrorHandler.handle(error))
            .finally(() => setBusy(false));
    }

    useEffect(() => {
        const loadAvailableRoles = async () => {
            setBusy(true);

            await RolesService
                .getApiRoles()
                .then(setRoles)
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }

        loadAvailableRoles();
    }, [])

    return (
        <Spin spinning={isBusy}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Title level={3}>
                    Регистрация нового пользователя
                </Typography.Title>
                <Typography.Text className="description-text">
                    Заполните все данные для создания аккаунта
                </Typography.Text>

                <Space direction="vertical" style={{ width: "100%", marginTop: 20 }} size={26}>
                    <Card>
                        <Row>
                            <Col xs={24} xxl={8} xl={16}>
                                <Space style={{ width: "100%" }} size={24} direction="vertical" >
                                    <Card>
                                        <Space align="center">
                                            <Avatar size={55} src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`} />
                                            <Space direction="vertical">
                                                <Typography.Title style={{ margin: "auto auto", color: "#1677FF" }} level={5}>{
                                                    (fullName && fullName?.length > 0) ?
                                                        fullName :
                                                        'Имя пользователя'}</Typography.Title>

                                                <Typography.Text className="description-text">{
                                                    (email && email?.length > 0) ?
                                                        email :
                                                        'example@example.com'
                                                }</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Card>
                                    {
                                        currentStep == 0 &&
                                        <Form>
                                            <Form.Item>
                                                <Input
                                                    prefix={<CiUser />}
                                                    key="fullName"
                                                    value={fullName}
                                                    onChange={onFullNameChanged}
                                                    placeholder="ФИО"
                                                ></Input>
                                            </Form.Item>
                                            <Form.Item>
                                                <Input
                                                    key="email"
                                                    prefix={<CiMail />}
                                                    value={email}
                                                    onChange={onEmailChanged}
                                                    placeholder="Почтовый адрес"
                                                ></Input>
                                            </Form.Item>
                                            <Form.Item>
                                                <Input
                                                    key="phone"
                                                    prefix={<CiPhone />}
                                                    value={phone}
                                                    onChange={onPhoneChanged}
                                                    placeholder="Номер телефона"
                                                ></Input>
                                            </Form.Item>

                                            <Space direction="vertical" size={15} style={{ width: "100%" }}>
                                                <Space direction="vertical" style={{ width: "100%" }}>
                                                    <Button onClick={nextStep} block type="primary">
                                                        Продолжить
                                                    </Button>
                                                </Space>
                                            </Space>
                                        </Form>
                                    }
                                    {
                                        currentStep == 1 &&
                                        <Form>
                                            <Form.Item>
                                                <Input.Password
                                                    required={true}
                                                    value={password}
                                                    onChange={onPasswordChanged}
                                                    placeholder="Пароль"
                                                ></Input.Password>
                                            </Form.Item>

                                            <Form.Item>
                                                <Input.Password
                                                    required={true}
                                                    value={confirmPassword}
                                                    onChange={onConfirmPasswordChanged}
                                                    placeholder="Повторите пароль"
                                                ></Input.Password>
                                            </Form.Item>

                                            <Form.Item>
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Выберите роли пользователя"
                                                    value={selectedRoles}
                                                    onChange={onRolesChanged}
                                                    options={roles.map((item) => ({
                                                        value: item.name,
                                                        label: item.name,
                                                    }))}
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item>

                                            <Space direction="vertical" size={15} style={{ width: "100%" }}>
                                                <Space direction="vertical" style={{ width: "100%" }}>
                                                    <Button onClick={createUser} block type="primary">
                                                        Создать
                                                    </Button>
                                                    <Button onClick={backStep} block type="dashed">
                                                        Вернуться назад
                                                    </Button>
                                                </Space>
                                            </Space>
                                        </Form>
                                    }
                                </Space>
                            </Col>
                            <Col span={1}></Col>
                            <Col xs={0} xxl={7} xl={7}>
                                <Steps
                                    direction="vertical"
                                    current={currentStep}
                                    items={[
                                        {
                                            title: 'Контактные данные',
                                            description: 'Заполните контактные данные пользователя'
                                        },
                                        {
                                            title: 'Доступ',
                                            description: 'Заполните данные для доступа пользователя к платформе',
                                        },
                                    ]}>

                                </Steps>
                            </Col>
                        </Row>
                    </Card>
                </Space>
            </Space>
        </Spin>
    );
}

export default CreateUserPage;
