/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CityDto } from '../models/CityDto';
import type { TimeZoneDto } from '../models/TimeZoneDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CitiesService {

    /**
     * @param query 
     * @returns CityDto Success
     * @throws ApiError
     */
    public static getApiCities(
query?: string,
): CancelablePromise<Array<CityDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Cities',
            query: {
                'query': query,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param lat 
     * @param lng 
     * @returns TimeZoneDto Success
     * @throws ApiError
     */
    public static getApiCitiesTimeZone(
lat?: number,
lng?: number,
): CancelablePromise<TimeZoneDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Cities/TimeZone',
            query: {
                'lat': lat,
                'lng': lng,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
