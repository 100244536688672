import { Divider, Space, Typography } from "antd"
import { useState } from "react";
import { ProductionEmployeesEdit } from "./ProductionEmployeesEdit";
import { ProductionEmployeesInfo } from "./ProductionEmployeesInfo";

export const ProductionEmployees: React.FC = () =>
{
    const [isEdit, setEdit] = useState<boolean>(false);

    return (<Space direction="vertical" style={ { width: "100%" } }>
        <Typography.Title level={ 5 }>
            Информация о работниках компании, которые принимают участие в этом проекте
        </Typography.Title>
        <Typography.Text className="description-text">
            Здесь вы можете посмотреть или изменить информацию о работниках, которые принимаю участие в этом проекте.
        </Typography.Text>
        <Divider />

        { isEdit && <ProductionEmployeesEdit onClick={ () => setEdit(false) } /> }
        { !isEdit && <ProductionEmployeesInfo onClick={ () => setEdit(true) } /> }

    </Space>);
}