import { Button, Result, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export type NotFoundPageProps = {
    Text: string;
    RefirectLocation: string;
};

export const NotFoundPage: React.FC<NotFoundPageProps> = ({
    Text,
    RefirectLocation: RedirectLocation,
}) => {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Result
                status="404"
                title="404"
                subTitle={Text}
                extra={
                    <Button type="primary">
                        <Link to={RedirectLocation}>Вернуться назад</Link>
                    </Button>
                }
            />
        </Space>
    );
};
