import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { ComponentDto, MetricValue } from "../../../services/openapi";

export const ComponentChart: React.FC<{
    component: ComponentDto;
    values: Array<MetricValue>;
}> = ({ values }) => {
    const chartValues = values
        .map((item) => ({
            value: item.value,
            date: new Date(item.snapshotTime),
            snapshotTimeString: new Date(item.snapshotTime).toLocaleString(),
        }))
        .sort((prev, next) => {
            if (prev.date < next.date) {
                return -1;
            }

            if (prev.date > next.date) {
                return 1;
            }

            return 0;
        });

    return (
        <ResponsiveContainer width="100%" height="100%" minHeight={300}>
            <LineChart width={500} height={300} data={chartValues}>
                <XAxis dataKey="snapshotTimeString" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line stroke="var(--color-title-primary)" fill="var(--color-title-primary)" dot dataKey="value" name="Значение" />
            </LineChart>
        </ResponsiveContainer>
    );
};
