/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmployeeType {
    DEVELOPER = 'Developer',
    MANAGER = 'Manager',
    ENGINEER = 'Engineer',
}
