import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthorizationState } from "../../services/openapi/models/AuthorizationState";
import AuthStore from "../../store/auth/authStore";

export interface PrivateRoutesProps {
    onAuthorizationStateRefreshed(state: AuthorizationState): void;
}

export const PrivateRoutes: React.FC<PrivateRoutesProps> = ({
    onAuthorizationStateRefreshed,
}) => {
    const [isBusy, setBusy] = useState<boolean>(true);
    const [
        authorizationState,
        setAuthorizationState,
    ] = useState<AuthorizationState | null>();

    useEffect(() => {
        async function getState() {
            setBusy(true);

            await AuthStore.getAuthorizationState().then((state) => {
                if (state) {
                    onAuthorizationStateRefreshed(state);
                }

                setAuthorizationState(state);
                setBusy(false);
            });
        }

        getState();
    }, []);

    if (isBusy) {
        return <></>;
    }

    if (authorizationState) {
        return <Outlet />;
    }

    return <Navigate to={"/login"} />;
};

export default PrivateRoutes;
