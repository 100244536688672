/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComponentDto } from "../models/ComponentDto";
import type { CreateComponentRequest } from "../models/CreateComponentRequest";
import type { MetricValue } from "../models/MetricValue";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { UpdateComponentRequest } from "../models/UpdateComponentRequest";

export class ComponentsService {
    /**
     * @param requestBody
     * @returns ComponentDto Success
     * @throws ApiError
     */
    public static postApiComponents(
        requestBody?: CreateComponentRequest
    ): CancelablePromise<ComponentDto> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/Components",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param productionId
     * @returns ComponentDto Success
     * @throws ApiError
     */
    public static getApiComponents(
        productionId?: string
    ): CancelablePromise<Array<ComponentDto>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Components",
            query: {
                productionId: productionId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    public static getApiComponent(
        componentId?: string
    ): CancelablePromise<ComponentDto> {
        return __request(OpenAPI, {
            method: "GET",
            url: `/api/Components/${componentId}`,
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param componentId
     * @param offset
     * @param quantity
     * @returns MetricValue Success
     * @throws ApiError
     */
    public static getApiComponentsValues(
        componentId: string,
        fromDate?: string,
        toDate?: string
    ): CancelablePromise<Array<MetricValue>> {
        return __request(OpenAPI, {
            method: "GET",
            url: `/api/Components/${componentId}/values`,
            query: {
                fromDate: fromDate,
                toDate: toDate,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param componentId
     * @returns ComponentDto Success
     * @throws ApiError
     */
    public static postApiComponentsReset(
        componentId?: string,
        resetDate?: string
    ): CancelablePromise<ComponentDto> {
        return __request(OpenAPI, {
            method: "POST",
            url: `/api/Components/${componentId}/Reset`,
            mediaType: "application/json",
            query: {
                fromDate: resetDate,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param componentId
     * @param requestBody
     * @returns ComponentDto Success
     * @throws ApiError
     */
    public static putApiComponents(
        componentId?: string,
        requestBody?: UpdateComponentRequest
    ): CancelablePromise<ComponentDto> {
        return __request(OpenAPI, {
            method: "PUT",
            url: `/api/Components/${componentId}`,
            body: requestBody,
            mediaType: "application/json",
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param componentId
     * @returns ComponentDto Success
     * @throws ApiError
     */
    public static deleteApiComponents(
        componentId?: string
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: `/api/Components/${componentId}`,
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns Array<string> Success
     * @throws ApiError
     */
    public static getApiComponentsList(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Components/List",
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
