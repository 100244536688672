import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionDto } from "../services/openapi";

type ProductionState = {
  production: ProductionDto | null;
};

const initialState: ProductionState = {
  production: null,
};

const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    setProduction(state, action: PayloadAction<ProductionDto>) {
      state.production = action.payload;
    },
  },
});

export const { setProduction } = productionSlice.actions;
export default productionSlice.reducer;
