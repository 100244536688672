import { useState } from "react";
import { Divider, Space, Typography } from "antd";
import { ProductionCommentEdit } from "./ProductionCommentEdit";
import { ProductionCommentInfo } from "./ProductionCommentInfo";

export const ProductionComment: React.FC = () =>
{
    const [isEdit, setEdit] = useState<boolean>(false);

    return (<Space direction="vertical" style={ { width: "100%" } }>
        <Typography.Title level={ 5 }>
            Комментарии к производству
        </Typography.Title>
        <Typography.Text className="description-text">
            Здесь вы можете посмотреть или изменить комментарий к производству.
        </Typography.Text>

        <Divider />

        {
            !isEdit ?
                <ProductionCommentInfo onClick={ () => setEdit(true) } /> :
                <ProductionCommentEdit onClick={ () => setEdit(false) } />
        }
    </Space >);
}