import { Avatar, Button, Col, Divider, Empty, Row, Select, Space, Spin, Tooltip, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/hook";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { CommentDto, ProductionsService } from "../../../../services/openapi";


export const ProductionCommentInfo: React.FC<{ onClick(): void; }> = ({ onClick }) => {
    const production = useAppSelector(state => state.productionState.production!);

    const [isBusy, setBusy] = useState<boolean>(false);
    const [comments, setComments] = useState<Array<CommentDto>>([]);
    const [commentsOptions, setCommentsOptions] = useState<DefaultOptionType[]>([]);
    const [selectedComment, setSelectedComment] = useState<CommentDto | undefined | null>();
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const onSelectedCommentChanged = (value: string) => setSelectedComment(comments.find(x => x.editDate == value));

    useEffect(() => {
        const loadComments = async () => {
            setBusy(true);

            await ProductionsService
                .getApiProductionsComments(production.id)
                .then(result => {
                    setComments(result.sort((x, y) => (x.editDate > y.editDate) ? 0 : -1));

                    setSelectedComment(production.lastComment);

                    setCommentsOptions(comments.map<DefaultOptionType>(x => ({
                        label: new Date(x.editDate).toLocaleString(),
                        value: x.editDate
                    })));

                    setIsMounted(true);
                })
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        };

        loadComments();
    }, [isMounted]);

    return <Spin spinning={isBusy}>
        <Space direction="vertical" style={{ width: "100%" }}>
            {Object.keys(selectedComment ?? {}).length ?
                <Space direction="vertical" style={{ width: "100%" }} size={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row>
                            <Col span={16}>
                                <Tooltip color="geekblue" title="Автор последнего изменения">
                                    <Space>
                                        <Avatar size={55} src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`} />
                                        <Space direction="vertical">
                                            <Typography.Title style={{ margin: "auto auto", color: "#1677FF" }} level={5}>
                                                {selectedComment!.author?.fullname}
                                            </Typography.Title>

                                            <Typography.Text className="description-text">
                                                {selectedComment!.author?.email}
                                            </Typography.Text>
                                        </Space>
                                    </Space>
                                </Tooltip>
                            </Col>

                            <Col>
                                <Space>
                                    <Select
                                        style={{ width: 240 }}
                                        onChange={onSelectedCommentChanged}
                                        placeholder="История"
                                        options={commentsOptions} />
                                    <Button onClick={onClick} type="dashed">Изменить</Button>
                                </Space>
                            </Col>
                        </Row>

                        <Divider></Divider>
                        <Typography.Text style={{ whiteSpace: "pre-wrap", maxHeight: 2 }}>
                            {selectedComment!.text!}
                        </Typography.Text>
                    </Space>
                </Space>
                :
                <Empty description="Пока что нет комментария" style={{ margin: "0 auto" }}>
                    <Button
                        onClick={onClick}
                        style={{ margin: "0 auto", display: "absolute" }} type="primary">Заполнить</Button>
                </Empty>}
        </Space>
    </Spin>;
};
