import { Button, Card, Col, Divider, Empty, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { ProductionsService, RemoteApplicationDto, RemoteApplicationType } from "../../../../services/openapi";
import { setProduction } from "../../../../store/productionSlice";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { VscDebugDisconnect } from "react-icons/vsc";
import { AiOutlineDelete } from "react-icons/ai";
import Copy from "../../../../services/copy/Copy";
import { ApplicationIcon } from "./ApplicationIcon";


export const ProductionRemoteInfo: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const [isBusy, setBusy] = useState<boolean>(false);

    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const updateProduction = async () =>
    {
        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const getHref = (connection: RemoteApplicationDto): string =>
    {
        let remoteApplicationHref = '';

        switch (connection.remoteApplicationType)
        {
            case RemoteApplicationType.ANY_DESK: remoteApplicationHref = "anydesk:"; break;
            case RemoteApplicationType.TEAM_VIEWER: remoteApplicationHref = "teamviewer:"; break;
            case RemoteApplicationType.RUST_DESK: remoteApplicationHref = "teamviewer:"; break;
            case RemoteApplicationType.MICROSOFT_REMOTE: remoteApplicationHref = "msrdp:"; break;
        }

        return `${ remoteApplicationHref }${ connection.username }`;
    };

    const onConnectClicked = (connection: RemoteApplicationDto) =>
    {
        Copy(connection.password!);
    };

    const onDeleteConnectionClicked = async (connection: RemoteApplicationDto) =>
    {
        setBusy(true);

        await ProductionsService
            .deleteApiProductionsRemoteApplications(production.id, connection.id)
            .then(async () => await updateProduction())
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    return (
        <Spin spinning={ isBusy }>
            <Space direction="vertical" style={ { width: "100%" } }>
                { Object.keys(production.remoteApplications ?? {}).length > 0 ?
                    <Space direction="vertical" style={ { width: "100%" } }>
                        <Row >
                            { production.remoteApplications?.map(application =>
                            {
                                return (
                                    <Col key={ application.id } span={ 24 } >
                                        <Card actions={ [
                                            <Tooltip title="Подключиться" color="geekblue">
                                                <Button href={ getHref(application) } onClick={ () => onConnectClicked(application) } type="link"><VscDebugDisconnect /></Button>
                                            </Tooltip>,

                                            <Tooltip title="Удалить подключение" color="geekblue">
                                                <Popconfirm okText="Да, удалить" onConfirm={ () => onDeleteConnectionClicked(application) } cancelText="Нет" title="Вы уверены что хотите удалить подключение?">
                                                    <Button type="link" danger><AiOutlineDelete /></Button>
                                                </Popconfirm>
                                            </Tooltip>
                                        ] }>
                                            <ApplicationIcon applicationType={ application.remoteApplicationType! } />
                                            <Divider plain type="vertical" />
                                            <Space direction="vertical">
                                                <Typography.Title style={ { margin: "auto auto", color: "#1677FF" } } level={ 5 }>{ application.title }</Typography.Title>

                                                <Space>
                                                    <Typography.Text><strong>Логин: </strong>{ application.username }</Typography.Text>
                                                    <Divider type="vertical" />
                                                    <Typography.Text><strong>Пароль: </strong>{ application.password }</Typography.Text>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>);
                            }) }
                        </Row>
                        <Button type="primary" color="primary" onClick={ onClick }>Добавить подключение</Button>
                    </Space> :
                    <Empty description="Нет информации о программах для удаленного подключения">
                        <Button type="primary" onClick={ onClick }>Добавить подключение</Button>
                    </Empty> }
            </Space>
        </Spin>
    );
};
