/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivationKeyDto } from '../models/ActivationKeyDto';
import type { AddApplicationSettingsRequest } from '../models/AddApplicationSettingsRequest';
import type { AddCommentRequest } from '../models/AddCommentRequest';
import type { AddProductionContactsRequest } from '../models/AddProductionContactsRequest';
import type { AddProductionRequest } from '../models/AddProductionRequest';
import type { AddRemoteApplicationRequest } from '../models/AddRemoteApplicationRequest';
import type { ApplicationSettingsDto } from '../models/ApplicationSettingsDto';
import type { CommentDto } from '../models/CommentDto';
import type { CreateFolderRequest } from '../models/CreateFolderRequest';
import type { FileDto } from '../models/FileDto';
import type { FileDtoPagedList } from '../models/FileDtoPagedList';
import type { FolderDto } from '../models/FolderDto';
import type { PLCDto } from '../models/PLCDto';
import type { ProductionContactDto } from '../models/ProductionContactDto';
import type { ProductionDto } from '../models/ProductionDto';
import type { ProductionDtoPagedList } from '../models/ProductionDtoPagedList';
import type { RemoteApplicationDto } from '../models/RemoteApplicationDto';
import type { UpdateApplicationSettingsRequest } from '../models/UpdateApplicationSettingsRequest';
import type { UpdateFileRequest } from '../models/UpdateFileRequest';
import type { UpdateFolderRequest } from '../models/UpdateFolderRequest';
import type { UpdatePLCRequest } from '../models/UpdatePLCRequest';
import type { UpdateProductionContactsRequest } from '../models/UpdateProductionContactsRequest';
import type { UpdateProductionRequest } from '../models/UpdateProductionRequest';
import type { UpdateRemoteApplicationRequest } from '../models/UpdateRemoteApplicationRequest';
import type { UpdateServerSettingsRequest } from '../models/UpdateServerSettingsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductionsService {

    /**
     * @param query 
     * @param offset 
     * @param quantity 
     * @returns ProductionDtoPagedList Success
     * @throws ApiError
     */
    public static getApiProductions(
query: string = '',
offset?: number,
quantity: number = 10,
): CancelablePromise<ProductionDtoPagedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions',
            query: {
                'query': query,
                'offset': offset,
                'quantity': quantity,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProductionDto Success
     * @throws ApiError
     */
    public static postApiProductions(
requestBody?: AddProductionRequest,
): CancelablePromise<ProductionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param query 
     * @param offset 
     * @param quantity 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiProductionsSearchJenkinsProductions(
query: string = '',
offset?: number,
quantity: number = 5,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions/SearchJenkinsProductions',
            query: {
                'query': query,
                'offset': offset,
                'quantity': quantity,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductions(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns ProductionDto Success
     * @throws ApiError
     */
    public static getApiProductions1(
id: string,
): CancelablePromise<ProductionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductionDto Success
     * @throws ApiError
     */
    public static putApiProductions(
id: string,
requestBody?: UpdateProductionRequest,
): CancelablePromise<ProductionDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Productions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns CommentDto Success
     * @throws ApiError
     */
    public static postApiProductionsComments(
id: string,
requestBody?: AddCommentRequest,
): CancelablePromise<CommentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/Comments',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns CommentDto Success
     * @throws ApiError
     */
    public static getApiProductionsComments(
id: string,
): CancelablePromise<Array<CommentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions/{id}/Comments',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns PLCDto Success
     * @throws ApiError
     */
    public static putApiProductionsPlc(
id: string,
requestBody?: UpdatePLCRequest,
): CancelablePromise<PLCDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Productions/{id}/PLC',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param employeeId 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiProductionsEmployees(
id: string,
employeeId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/Employees',
            path: {
                'id': id,
            },
            query: {
                'employeeId': employeeId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param employeeId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductionsEmployees(
id: string,
employeeId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}/Employees',
            path: {
                'id': id,
            },
            query: {
                'employeeId': employeeId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param contactId 
     * @param requestBody 
     * @returns ProductionContactDto Success
     * @throws ApiError
     */
    public static putApiProductionsContacts(
id: string,
contactId: string,
requestBody?: UpdateProductionContactsRequest,
): CancelablePromise<ProductionContactDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Productions/{id}/Contacts/{contactId}',
            path: {
                'id': id,
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param contactId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductionsContacts(
id: string,
contactId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}/Contacts/{contactId}',
            path: {
                'id': id,
                'contactId': contactId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductionContactDto Success
     * @throws ApiError
     */
    public static postApiProductionsContacts(
id: string,
requestBody?: AddProductionContactsRequest,
): CancelablePromise<ProductionContactDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/Contacts',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ApplicationSettingsDto Success
     * @throws ApiError
     */
    public static putApiProductionsServerSettings(
id: string,
requestBody?: UpdateServerSettingsRequest,
): CancelablePromise<ApplicationSettingsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Productions/{id}/ServerSettings',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param applicationSettingsId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductionsApplicationSettings(
id: string,
applicationSettingsId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}/ApplicationSettings/{applicationSettingsId}',
            path: {
                'id': id,
                'applicationSettingsId': applicationSettingsId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param applicationSettingsId 
     * @param requestBody 
     * @returns ApplicationSettingsDto Success
     * @throws ApiError
     */
    public static putApiProductionsApplicationSettings(
id: string,
applicationSettingsId: string,
requestBody?: UpdateApplicationSettingsRequest,
): CancelablePromise<ApplicationSettingsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Productions/{id}/ApplicationSettings/{applicationSettingsId}',
            path: {
                'id': id,
                'applicationSettingsId': applicationSettingsId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ApplicationSettingsDto Success
     * @throws ApiError
     */
    public static postApiProductionsApplicationSettings(
id: string,
requestBody?: AddApplicationSettingsRequest,
): CancelablePromise<ApplicationSettingsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/ApplicationSettings',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns ActivationKeyDto Success
     * @throws ApiError
     */
    public static postApiProductionsActivate(
id: string,
): CancelablePromise<ActivationKeyDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/Activate',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns RemoteApplicationDto Success
     * @throws ApiError
     */
    public static postApiProductionsRemoteApplications(
id: string,
requestBody?: AddRemoteApplicationRequest,
): CancelablePromise<RemoteApplicationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/RemoteApplications',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param remoteApplicationId 
     * @param requestBody 
     * @returns RemoteApplicationDto Success
     * @throws ApiError
     */
    public static patchApiProductionsRemoteApplications(
id: string,
remoteApplicationId: string,
requestBody?: UpdateRemoteApplicationRequest,
): CancelablePromise<RemoteApplicationDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/Productions/{id}/RemoteApplications/{remoteApplicationId}',
            path: {
                'id': id,
                'remoteApplicationId': remoteApplicationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param remoteApplicationId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductionsRemoteApplications(
id: string,
remoteApplicationId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}/RemoteApplications/{remoteApplicationId}',
            path: {
                'id': id,
                'remoteApplicationId': remoteApplicationId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param folderId 
     * @returns FolderDto Success
     * @throws ApiError
     */
    public static getApiProductionsFolders(
id: string,
folderId?: number,
): CancelablePromise<FolderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions/{id}/Folders',
            path: {
                'id': id,
            },
            query: {
                'folderId': folderId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FolderDto Success
     * @throws ApiError
     */
    public static postApiProductionsFolders(
id: string,
requestBody?: CreateFolderRequest,
): CancelablePromise<FolderDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/Folders',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param folderId 
     * @param requestBody 
     * @returns FolderDto Success
     * @throws ApiError
     */
    public static patchApiProductionsFolders(
id: string,
folderId?: number,
requestBody?: UpdateFolderRequest,
): CancelablePromise<FolderDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/Productions/{id}/Folders',
            path: {
                'id': id,
            },
            query: {
                'folderId': folderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param folderId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductionsFolders(
id: string,
folderId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}/Folders/{folderId}',
            path: {
                'id': id,
                'folderId': folderId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param query 
     * @param offset 
     * @param quantity 
     * @returns FileDtoPagedList Success
     * @throws ApiError
     */
    public static getApiProductionsFiles(
id: string,
query: string,
offset?: number,
quantity?: number,
): CancelablePromise<FileDtoPagedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions/{id}/Files',
            path: {
                'id': id,
            },
            query: {
                'query': query,
                'offset': offset,
                'quantity': quantity,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param folderId 
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiProductionsFiles(
id: string,
folderId: number,
formData?: {
formFile?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Productions/{id}/Files',
            path: {
                'id': id,
            },
            query: {
                'folderId': folderId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param fileId 
     * @param requestBody 
     * @returns FileDto Success
     * @throws ApiError
     */
    public static patchApiProductionsFiles(
id: string,
fileId?: string,
requestBody?: UpdateFileRequest,
): CancelablePromise<FileDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/Productions/{id}/Files',
            path: {
                'id': id,
            },
            query: {
                'fileId': fileId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param fileId 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiProductionsFiles1(
id: string,
fileId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Productions/{id}/Files/{fileId}',
            path: {
                'id': id,
                'fileId': fileId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param fileId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiProductionsFiles(
id: string,
fileId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Productions/{id}/Files/{fileId}',
            path: {
                'id': id,
                'fileId': fileId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
