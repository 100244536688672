/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddEmployeeRequest } from '../models/AddEmployeeRequest';
import type { EmployeeDto } from '../models/EmployeeDto';
import type { EmployeeDtoPagedList } from '../models/EmployeeDtoPagedList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeesService {

    /**
     * @param query 
     * @param offset 
     * @param quantity 
     * @returns EmployeeDtoPagedList Success
     * @throws ApiError
     */
    public static getApiEmployees(
query?: string,
offset?: number,
quantity: number = 5,
): CancelablePromise<EmployeeDtoPagedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Employees',
            query: {
                'query': query,
                'offset': offset,
                'quantity': quantity,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EmployeeDto Success
     * @throws ApiError
     */
    public static postApiEmployees(
requestBody?: AddEmployeeRequest,
): CancelablePromise<EmployeeDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Employees',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiEmployees(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Employees/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns EmployeeDto Success
     * @throws ApiError
     */
    public static getApiEmployees1(
id: string,
): CancelablePromise<EmployeeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Employees/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
