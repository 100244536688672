import { Navigate } from "react-router-dom";
import Locations from "../../consts/Locations";
import { AuthorizationState } from "../../services/openapi/models/AuthorizationState";

export type PrivateRouteProps = {
    authorizationState: AuthorizationState;
    requiredRoles: string[];
    element: React.ReactNode
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
    authorizationState,
    requiredRoles,
    element
}) => {
    if (authorizationState.roles.some((role) => requiredRoles.includes(role))) {
        return element;
    }
    return <Navigate to={Locations.Login} />;
};
