import React from "react";
import { FileDto } from "../../../services/openapi";
import { Col, Tooltip, Typography } from "antd";


export const DocumentSize: React.FC<{ document: FileDto; }> = ({ document }) =>
{
    const formatBytes = (bytes: number, decimals = 2): string =>
    {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЕБ', 'ЗБ', 'ЙБ'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    return (
        <Col span={ 4 }>
            <Tooltip title="Размер файла" color="geekblue">
                <Typography.Text className="description-text">{ formatBytes(document.fileSize) }</Typography.Text>
            </Tooltip>
        </Col>
    );
};
