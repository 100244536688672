import { Col, Row, Typography } from "antd";
import { FolderDto } from "../../../services/openapi";
import { BiSolidFolder } from "react-icons/bi";

export const BackFolder: React.FC<{ currentFolder?: FolderDto| undefined, onOpen(folder?: FolderDto | undefined): Promise<void> }> = ({ currentFolder, onOpen }) =>
{
    if (currentFolder?.parentFolder)
    {
        return (
            <Row align={ "middle" }>
                <Col span={ "8" }>
                    <BiSolidFolder />
                    { <Typography.Link onClick={ () => onOpen(currentFolder) } style={ { marginLeft: 15 } }>...</Typography.Link> }
                </Col>
            </Row>
        );
    }
};