import {
    Alert,
    Avatar,
    Badge,
    Button,
    Card,
    Checkbox,
    DatePicker,
    Divider,
    Modal,
    Popconfirm,
    Space,
    Tooltip,
    Typography,
    message,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import {
    AddLicenseKeyRequest,
    LicenseStatus,
    LicensesService,
    ProductionsService,
} from "../../../services/openapi";
import { setProduction } from "../../../store/productionSlice";
import { CopyArea } from "../../copy-area/CopyArea";

export const ProductionAccess: React.FC = () => {
    const dispatch = useAppDispatch();
    const production = useAppSelector(
        (state) => state.productionState.production!
    );
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isUnlimitedLicense, setUnlimitedLicense] = useState<boolean>(false);
    const [
        isUnlimitedLicenseConfirmed,
        setUnlimitedLicenseConfirmed,
    ] = useState<boolean>(false);
    const [expireDateLicense, setExpireDateLicense] = useState<Dayjs>(dayjs());

    const onLicenseExpiresChanged = (newDate: Dayjs | null) =>
        newDate && setExpireDateLicense(newDate);
    const onLicenseUnlimitedChanged = (event: CheckboxChangeEvent) =>
        setUnlimitedLicense(event.target.checked);
    const onUnlimitedLicenseConfirmationChanged = (
        event: CheckboxChangeEvent
    ) => setUnlimitedLicenseConfirmed(event.target.checked);

    const getTimeOffset = (): number => {
        const timeZone = production.location.timeZone;
        const locaTimeZone = -new Date().getTimezoneOffset() / 60;
        const [hours] = timeZone.split(":").map(Number)!;

        return hours - locaTimeZone;
    };

    const updateProduction = async () => {
        await ProductionsService.getApiProductions1(production.id)
            .then((result) => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onCreateLicenseClicked = async () => {
        const request: AddLicenseKeyRequest = {
            productionId: production.id!,
            pcIdentificator: production.serverSettings.pcIdentificator,
            isUnlimited: isUnlimitedLicense,
            expireDateTime: expireDateLicense.toJSON(),
        };

        await LicensesService.postApiLicenses(request)
            .then(updateProduction)
            .then(() => setModalOpen(false))
            .then(() => message.success("Лицензия успешно выдана."))
            .catch(ErrorHandler.handle);
    };

    const onCreateKeyClicked = async () => {
        await ProductionsService.postApiProductionsActivate(production.id)
            .then((key) =>
                Modal.info({ title: "Новый API ключ", content: key.key })
            )
            .then(updateProduction)
            .catch(ErrorHandler.handle);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }} size={0}>
            <Space direction="vertical" size={24} style={{ width: "100%" }}>
                <Space direction="vertical" size={16} style={{ width: "100%" }}>
                    <Typography.Title level={4}>API-ключ</Typography.Title>

                    {production.activationStatus == "Activated" && (
                        <Alert
                            message="Производство активировано"
                            description="Производство успешно активировано и работает с SmartWeb.API."
                            type="success"
                            showIcon
                        />
                    )}

                    {production.activationStatus == "Ready" && (
                        <Alert
                            message="Производство ожидает ввода API ключа"
                            description="Ранее был выдан API ключ для активации производства, вставьте его в клиент SmartMix."
                            type="warning"
                            showIcon
                        />
                    )}

                    <Alert
                        style={{ width: "100%" }}
                        message={
                            <Space direction="vertical">
                                <Typography.Text>
                                    Ключ существует в единственном экземпляре.
                                    При его компроментации необходимо
                                    сгенерировать ключ заново. После генерации
                                    нового ключа, предыдущий ключ становится
                                    недействительным.
                                </Typography.Text>
                            </Space>
                        }
                    />
                </Space>

                <Popconfirm
                    onConfirm={onCreateKeyClicked}
                    title="Вы уверены что хотите активировать производство?"
                    description="Повторная генерация API ключа означает,
                         что вам будет необходимо по новой вставить API ключ в SmartMix"
                    okText="Да, сгенерировать новый ключ"
                    cancelText="Нет"
                >
                    <Button type="dashed" danger>
                        Сгенерировать новый ключ
                    </Button>
                </Popconfirm>
            </Space>

            <Divider></Divider>

            <Space direction="vertical" size={24} style={{ width: "100%" }}>
                <Space direction="vertical" size={2}>
                    <Typography.Title level={4}>Лицензия</Typography.Title>
                    <Typography.Text className="description-text"></Typography.Text>
                </Space>

                {!production.serverSettings?.pcIdentificator && (
                    <Alert
                        message="Лицензия не может быть выдана для этого производства"
                        description="Необходимо заполнить информацию о сервере. Вам необходимо зайти в Основное -> Сервер и заполнить данные."
                        type="warning"
                        showIcon
                    ></Alert>
                )}

                {production.licenseKey &&
                    Object.keys(production.licenseKey).length != 0 && (
                        <Card>
                            <Tooltip title="Автор лицензии" color="geekblue">
                                <Space align="center">
                                    <Avatar
                                        size={36}
                                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`}
                                    />
                                    <Space direction="vertical" size={0}>
                                        <Typography.Text
                                            style={{
                                                margin: "auto auto",
                                                color: "#1677FF",
                                            }}
                                        >
                                            {production.licenseKey.author
                                                ?.fullname ??
                                                "Система SmartWeb"}
                                        </Typography.Text>

                                        <Typography.Text className="description-text">
                                            {production.licenseKey.author
                                                ?.email ??
                                                "Лицензия была выдана автоматически при активации"}
                                        </Typography.Text>
                                    </Space>
                                </Space>
                            </Tooltip>
                            <Divider />
                            <Space direction="vertical">
                                <CopyArea
                                    description="Лицензионный ключ"
                                    textToCopy={production.licenseKey.key!}
                                ></CopyArea>
                                <Typography.Text className="description-text">
                                    Действует до:
                                    {production.licenseKey.isUnlimited
                                        ? ` Безлимитно`
                                        : ` ${new Date(
                                              production.licenseKey.expireIn!
                                          ).toLocaleString()}`}
                                </Typography.Text>

                                <Typography.Text className="description-text">
                                    {`Статус: `}
                                    {production.licenseKey.statusEnum ==
                                    LicenseStatus.APPLIED ? (
                                        <Space>
                                            <Typography.Text>{`Активирована`}</Typography.Text>
                                            <Badge color="green"></Badge>
                                        </Space>
                                    ) : (
                                        <Space>
                                            <Typography.Text className="description-text">{`Ожидает активации`}</Typography.Text>
                                            <Badge color="yellow"></Badge>
                                        </Space>
                                    )}
                                </Typography.Text>
                            </Space>
                        </Card>
                    )}

                {production.serverSettings?.pcIdentificator != null && (
                    <Space
                        direction="vertical"
                        style={{ width: "100%" }}
                        size={24}
                    >
                        {Object.keys(production.licenseKey ?? {}).length ==
                            0 && (
                            <Alert
                                message="Для производства ещё не была выдана лицензия"
                                description="Вы можете сгенерировать её нажав на кнопку ниже"
                                type="warning"
                                showIcon
                            ></Alert>
                        )}
                        <Button
                            type="primary"
                            onClick={() => setModalOpen(true)}
                        >
                            Сгенерировать лицензию
                        </Button>
                    </Space>
                )}

                <Modal
                    okButtonProps={{
                        disabled:
                            isUnlimitedLicense && !isUnlimitedLicenseConfirmed,
                    }}
                    open={isModalOpen}
                    onOk={onCreateLicenseClicked}
                    onCancel={() => setModalOpen(false)}
                    okText="Выдать лицензию"
                    cancelText="Отменить"
                    title="Генерация лицензии"
                >
                    <Space
                        direction="vertical"
                        style={{ width: "100%", marginTop: 20 }}
                        size={24}
                    >
                        <Alert
                            message="Время окончания лицензии"
                            description={
                                isUnlimitedLicense
                                    ? `Безлимитная лицензия`
                                    : `Дата и время окончания лицензии по вашему времени: ${expireDateLicense
                                          .add(getTimeOffset(), "hours")
                                          .format("DD.MM.YYYY HH:00")}`
                            }
                            type="warning"
                            showIcon
                        ></Alert>

                        {!isUnlimitedLicense && (
                            <DatePicker
                                showTime
                                onChange={onLicenseExpiresChanged}
                                value={expireDateLicense}
                                placeholder="Время окончания лицензии"
                                format={"DD.MM.YYYY HH:00"}
                                style={{ width: "100%" }}
                            />
                        )}

                        <Checkbox
                            onChange={onLicenseUnlimitedChanged}
                            checked={isUnlimitedLicense}
                        >
                            Безлимитная лицензия
                        </Checkbox>

                        {isUnlimitedLicense && (
                            <Card>
                                <Space
                                    direction="vertical"
                                    style={{ width: "100%" }}
                                >
                                    <Space>
                                        <Typography.Text>
                                            Вы уверены что хотите выдать
                                            безлимитную лицензию?
                                        </Typography.Text>
                                    </Space>
                                    <Checkbox
                                        onChange={
                                            onUnlimitedLicenseConfirmationChanged
                                        }
                                        checked={isUnlimitedLicenseConfirmed}
                                    >
                                        Я подтверждаю безлимитную выдачу
                                        лицензии
                                    </Checkbox>
                                </Space>
                            </Card>
                        )}
                    </Space>
                </Modal>
            </Space>
        </Space>
    );
};
