export type MayBeProps = {
    condition(): boolean;
    content: React.ReactNode;
};

/**
 * MayBe is a React component that conditionally renders one of two components
 * based on the result of the Expression prop.
 *
 * It renders the IfTrue component if Expression returns true,
 * otherwise it renders the IfFalse component.
 */
export const MayBe: React.FC<MayBeProps> = ({ condition, content }) => {
    if (condition()) {
        return content;
    }
};
