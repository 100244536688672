import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { AuthorizationContext } from "./components/private-route/AuthorizationContext";
import { PrivateRoute } from "./components/private-route/PrivateRoute";
import PrivateRoutes from "./components/private-route/PrivateRoutes";
import { initialAuthorizationState } from "./components/private-route/initialAuthorizationState";
import Locations from "./consts/Locations";
import Roles from "./consts/Roles";
import PrivateLayout from "./layouts/PrivateLayout";
import { CreateProductionPage } from "./pages/CreateProductionPage";
import CreateUserPage from "./pages/CreateUserPage";
import { NotFoundPage } from "./pages/ForbiddenPage";
import { LicensePage } from "./pages/LicensePage";
import LoginPage from "./pages/LoginPage";
import ProductionPage from "./pages/ProductionPage";
import { ProductionsPage } from "./pages/ProductionsPage";
import UserPage from "./pages/UserPage";
import UsersPage from "./pages/UsersPage";
import { AuthorizationState } from "./services/openapi/models/AuthorizationState";
import AuthStore from "./store/auth/authStore";

export const App: React.FC = () => {
    const [authState, setAuthState] = useState<AuthorizationState>(
        initialAuthorizationState
    );

    if (AuthStore.isBusy) {
        <></>
    }

    return (
        <AuthorizationContext.Provider value={{ authState }}>
            <Router>
                <Routes>
                    <Route
                        element={
                            <PrivateRoutes
                                onAuthorizationStateRefreshed={setAuthState}
                            />
                        }
                    >
                        <Route
                            element={
                                <PrivateLayout authorizationState={authState} />
                            }
                        >
                            <Route
                                path={Locations.Default}
                                element={
                                    <PrivateRoute
                                        element={<ProductionsPage />}
                                        authorizationState={authState}
                                        requiredRoles={[
                                            Roles.Admin,
                                            Roles.Developer,
                                            Roles.Seller,
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.Productions}
                                element={
                                    <PrivateRoute
                                        element={<ProductionsPage />}
                                        authorizationState={authState}
                                        requiredRoles={[
                                            Roles.Admin,
                                            Roles.Developer,
                                            Roles.Seller,
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.CreateUser}
                                element={
                                    <PrivateRoute
                                        element={<CreateUserPage />}
                                        authorizationState={authState}
                                        requiredRoles={[Roles.Admin]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.Licenses}
                                element={
                                    <PrivateRoute
                                        element={<LicensePage />}
                                        authorizationState={authState}
                                        requiredRoles={[Roles.Admin]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.CreateProduction}
                                element={
                                    <PrivateRoute
                                        element={<CreateProductionPage />}
                                        authorizationState={authState}
                                        requiredRoles={[
                                            Roles.Admin,
                                            Roles.Developer,
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.Users}
                                element={
                                    <PrivateRoute
                                        element={<UsersPage />}
                                        authorizationState={authState}
                                        requiredRoles={[Roles.Admin]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.Productions}
                                element={
                                    <PrivateRoute
                                        element={<ProductionsPage />}
                                        authorizationState={authState}
                                        requiredRoles={[
                                            Roles.Admin,
                                            Roles.Developer,
                                        ]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.User}
                                element={
                                    <PrivateRoute
                                        element={<UserPage />}
                                        authorizationState={authState}
                                        requiredRoles={[Roles.Admin]}
                                    />
                                }
                            />
                            <Route
                                path={Locations.Production}
                                element={
                                    <PrivateRoute
                                        element={<ProductionPage />}
                                        authorizationState={authState}
                                        requiredRoles={[
                                            Roles.Admin,
                                            Roles.Seller,
                                            Roles.Developer,
                                        ]}
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route
                        path={Locations.Login}
                        element={<LoginPage />}
                    ></Route>

                    <Route
                        path={"*"}
                        element={
                            <NotFoundPage Text="Страница не найдена" RefirectLocation={Locations.Productions} />
                        }
                    />
                </Routes>
            </Router>
        </AuthorizationContext.Provider>
    );
};

export default App;
