import { Button, Input, InputProps, Row, Space, Spin, Typography } from "antd";
import { ChangeEvent, useState } from "react";
import Locations from "../consts/Locations";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import { AuthorizationService, LoginRequest } from "../services/openapi";

import { Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";

import { ApplicationLogo } from "../layouts/PrivateLayout";
import "./LoginPage.css";

const LoginPage: React.FC = () => {
    const [isBusy, setBusy] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [status, setStatus] = useState<InputProps["status"] | undefined>(
        undefined
    );

    const navigate = useNavigate();

    const onEmailChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value);

    const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);

    const onLoginClicked = async () => {
        const loginRequest: LoginRequest = {
            login: email,
            password: password,
        };

        setBusy(true);
        setStatus(undefined);

        await AuthorizationService.postApiAuthorizationSignIn(loginRequest)
            .then(() => setBusy(false))
            .then(() => navigate(Locations.Productions))
            .catch((error) => {
                setStatus("error");
                ErrorHandler.handle(error);
            })
            .finally(() => setBusy(false));
    };

    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <Header
                style={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    position: "sticky",
                }}
            >
                <Row style={{ margin: "5px 0px" }}>
                    <ApplicationLogo />
                    <Typography.Title
                        level={5}
                        style={{ textAlign: "center", margin: "12px 15px" }}
                    >
                        SmartWeb
                    </Typography.Title>
                </Row>
            </Header>

            <Space
                direction="vertical"
                size={16}
                style={{ alignContent: "center", textAlign: "center" }}
                className="content"
            >
                <Space
                    direction="vertical"
                    style={{ alignContent: "center", textAlign: "center" }}
                    size={0}
                >
                    <Typography.Title level={3} style={{ textAlign: "center" }}>
                        Авторизация
                    </Typography.Title>
                    <Typography.Text
                        className="description-text"
                        style={{ textAlign: "center" }}
                    >
                        Введите ваш почтовый адрес и пароль
                    </Typography.Text>
                </Space>
                <Spin spinning={isBusy}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Input
                            status={status}
                            placeholder="Почтовый адрес"
                            value={email}
                            onChange={onEmailChanged}
                        ></Input>
                        <Input.Password
                            status={status}
                            placeholder="Пароль"
                            value={password}
                            onChange={onPasswordChanged}
                        ></Input.Password>
                        <Button
                            block
                            type="primary"
                            className="login-button"
                            onClick={onLoginClicked}
                        >
                            Войти
                        </Button>
                    </Space>
                </Spin>
            </Space>
        </Space>
    );
};

export default LoginPage;
