import { DatePicker, Divider, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import {
    ComponentDto,
    ComponentsService,
    MetricValue,
} from "../../../services/openapi";
import { ComponentInfo } from "./ComponentInfo";
import { ComponentToolbar } from "./ComponentToolbar";
import { ComponentChart } from "./ComponentChart";
import { RangeValue } from "./ComponentsView";
import { ComponentEditView } from "./ComponentEditView";

export const ComponentView: React.FC<{
    component: ComponentDto;
    onDelete(): Promise<void>;
    onClose(): void;
}> = ({ component, onClose, onDelete }) => {
    const [componentState, setComponentState] = useState<ComponentDto>(
        component
    );

    const [componentValues, setComponentValues] = useState<Array<MetricValue>>(
        []
    );

    const [dateRange, setDateRange] = useState<RangeValue>();
    const [isBusy, setBusy] = useState<boolean>();
    const [isEdit, setEdit] = useState<boolean>();

    const componentChangeHandler = async () => {
        await ComponentsService.getApiComponent(component.id)
            .then(async (component) => {
                setComponentState(component);
                setDateRange([
                    dayjs(component.firstReportDate),
                    dayjs(component.lastReportDate),
                ]);
                fetchValues([
                    dayjs(component.firstReportDate),
                    dayjs(component.lastReportDate),
                ]);
            })
            .catch((error) => ErrorHandler.handle(error));
    };

    const componentEditChangeHandler = (editMode: boolean) => {
        setEdit(editMode);
    };

    const fetchValues = async (dateRange: RangeValue) => {
        setBusy(true);

        await ComponentsService.getApiComponentsValues(
            component.id,
            dateRange?.[0]?.toJSON(),
            dateRange?.[1]?.toJSON()
        )
            .then((values) => setComponentValues(values))
            .catch((error) => ErrorHandler.handle(error))
            .finally(() => setBusy(false));
    };

    const dateRangeChangeHandler = async (date: RangeValue) => {
        setDateRange(date);
        await fetchValues(date);
    };

    useEffect(() => {
        const fetchComponent = async () => {
            setBusy(true);

            await ComponentsService.getApiComponent(component.id)
                .then((component) => {
                    setComponentState(component);
                    dateRangeChangeHandler([
                        dayjs(component.firstReportDate),
                        dayjs(component.lastReportDate),
                    ]);
                })
                .catch((error) => ErrorHandler.handle(error))
                .finally(() => setBusy(false));
        };

        fetchComponent();
    }, [component]);

    if (!isEdit) {
        return (
            <Spin spinning={isBusy}>
                <Space direction="vertical" size={0} style={{ width: "100%" }}>
                    <ComponentToolbar
                        component={componentState}
                        onChange={componentChangeHandler}
                        onClose={onClose}
                        onEdit={componentEditChangeHandler}
                        onDelete={onDelete}
                    />
                    <Divider />
                    <ComponentInfo component={componentState} />
                    <Divider />
                    <Space>
                        <Typography.Text>Выберите дату отчёта:</Typography.Text>
                        <DatePicker.RangePicker
                            allowClear={false}
                            size="small"
                            value={dateRange}
                            format="DD.MM.YYYY"
                            disabledDate={(date) =>
                                date < dayjs(componentState.firstReportDate) ||
                                date > dayjs(componentState.lastReportDate)
                            }
                            onChange={dateRangeChangeHandler}
                        />
                    </Space>
                    <Divider />
                    <ComponentChart
                        component={component}
                        values={componentValues}
                    />
                </Space>
            </Spin>
        );
    }

    if (isEdit) {
        return (
            <ComponentEditView
                component={componentState}
                onComponentUpdate={componentChangeHandler}
                onClose={onClose}
                onEdit={componentEditChangeHandler}
            />
        );
    }
};
