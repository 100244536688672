import { Button, Input, Select, Space, Spin, message } from "antd";
import { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { AddRemoteApplicationRequest, ProductionsService, RemoteApplicationType } from "../../../../services/openapi";
import { DefaultOptionType } from "antd/es/select";
import { setProduction } from "../../../../store/productionSlice";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";


export const ProductionRemoteEdit: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const applicationOptions: DefaultOptionType[] = [
        { label: "AnyDesk", value: RemoteApplicationType.ANY_DESK },
        { label: "TeamViewer", value: RemoteApplicationType.TEAM_VIEWER },
        { label: "RustDesk", value: RemoteApplicationType.RUST_DESK },
        { label: "MicrosoftRemote", value: RemoteApplicationType.MICROSOFT_REMOTE }
    ];

    const dispatch = useAppDispatch();
    const production = useAppSelector(state => state.productionState.production!);

    const [isBusy, setBusy] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [applicationType, setApplicationType] = useState<RemoteApplicationType>(RemoteApplicationType.ANY_DESK);

    const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => setTitle(event.target.value);
    const onUserNameChanged = (event: ChangeEvent<HTMLInputElement>) => setUserName(event.target.value);
    const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const updateProduction = async () =>
    {
        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onSaveClicked = async () =>
    {
        const request: AddRemoteApplicationRequest = {
            title: title,
            username: userName,
            password: password,
            remoteApplicationType: applicationType
        };

        setBusy(true);

        await ProductionsService
            .postApiProductionsRemoteApplications(production.id, request)
            .then(async () => await updateProduction())
            .then(() => setBusy(false))
            .then(() => onClick())
            .then(() => message.success("Подключение успешно добавлено"))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    return <Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
            <Input placeholder="Название подключения" onChange={ onTitleChanged } value={ title }></Input>
            <Input placeholder="Логин" onChange={ onUserNameChanged } value={ userName }></Input>
            <Input placeholder="Пароль" onChange={ onPasswordChanged } value={ password }></Input>
            <Select style={ { width: "100%" } } placeholder="Программа" onChange={ setApplicationType } value={ applicationType } options={ applicationOptions }>
            </Select>
            <Space>
                <Button type="dashed" onClick={ onClick }>Отменить</Button>
                <Button type="primary" onClick={ onSaveClicked }>Сохранить</Button>
            </Space>
        </Space>
    </Spin>;
};
