import { Button, DatePicker, Divider, Flex, List } from "antd";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hook";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { ComponentDto, ComponentsService } from "../../../services/openapi";
import { ComponentCreateView } from "./ComponentCreateView";
import { ComponentListItem } from "./ComponentListItem";
import { ComponentView } from "./ComponentView";
export type RangeValue = Parameters<
    NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

export enum ViewMode {
    View,
    Create,
}

export const ComponentsView: React.FC = () => {
    const production = useAppSelector(
        (state) => state.productionState.production!
    );

    const [isBusy, setBusy] = useState<boolean>();
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.View);
    const [component, setComponent] = useState<ComponentDto>();
    const [components, setComponents] = useState<ComponentDto[]>();

    const componentClickHandler = (component: ComponentDto) => {
        setComponent(component);
        setViewMode(ViewMode.View);
    };

    const componentCloseHandler = () => {
        setComponent(undefined);
        setViewMode(ViewMode.View);
    };

    const componentCreateHandler = () => {
        setComponent(undefined);
        setViewMode(ViewMode.Create);
    };

    const componentsChangeHandler = async () => {
        setBusy(true);

        await ComponentsService.getApiComponents(production.id)
            .then((components) => setComponents(components))
            .catch((error) => ErrorHandler.handle(error))
            .finally(() => setBusy(false));
    };

    useEffect(() => {
        const fetchComponents = async () => {
            setBusy(true);

            await ComponentsService.getApiComponents(production.id)
                .then((components) => setComponents(components))
                .catch((error) => ErrorHandler.handle(error))
                .finally(() => setBusy(false));
        };

        fetchComponents();
    }, [production]);

    if (viewMode == ViewMode.View) {
        if (!component) {
            return (
                <>
                    <Flex>
                        <Button onClick={componentCreateHandler} type="primary">
                            Создать компонент
                        </Button>
                    </Flex>
                    <Divider />
                    <List
                        dataSource={components}
                        loading={isBusy}
                        renderItem={(c) => (
                            <ComponentListItem
                                component={c}
                                onClick={componentClickHandler}
                            />
                        )}
                    ></List>
                </>
            );
        }

        if (component) {
            return (
                <ComponentView
                    component={component}
                    onDelete={componentsChangeHandler}
                    onClose={componentCloseHandler}
                />
            );
        }
    }

    if (viewMode == ViewMode.Create) {
        return (
            <ComponentCreateView
                onChange={componentsChangeHandler}
                onClose={componentCloseHandler}
            />
        );
    }
};
