import { Divider, Space, Typography } from "antd";
import { useState } from "react";
import { ProductionContactsInfo } from "./ProductionContactsInfo";
import { ProductionContactsEdit } from "./ProductionContactsEdit";

export const ProductionContacts: React.FC = () =>
{
    const [isEdit, setEdit] = useState<boolean>(false);

    return (<Space direction="vertical" style={ { width: "100%" } }>
        <Typography.Title level={ 5 }>
            Информация о контактах производства
        </Typography.Title>
        <Typography.Text className="description-text">
            Здесь вы можете посмотреть или изменить информацию о контактах производства.
        </Typography.Text>
        <Divider />

        { isEdit && <ProductionContactsEdit onClick={ () => setEdit(false) } /> }
        { !isEdit && <ProductionContactsInfo onClick={ () => setEdit(true) } /> }

    </Space>);
}