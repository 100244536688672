import { Collapse } from "antd";
import { IncludedEquipment, ValueUnitType } from "../../../services/openapi";
import { MetricsValuesChart } from "./MetricsValuesChart";


export const IncludedEquipmentMetricsList: React.FC<{ includedEquipment: IncludedEquipment; }> = ({ includedEquipment }) =>
{
    const metricsOptions = includedEquipment.metrics?.map((item, index) => ({
        key: index,
        label: `${ item.name } ${ item.valueUnitType === ValueUnitType.COUNTER ? 'Счётчик' : 'Моточасы' }`,
        children: <MetricsValuesChart metric={ item } />
    }));

    return <Collapse ghost items={ metricsOptions } />;
};
