import React from "react";
import { FolderDto } from "../../../services/openapi";
import { Col, Typography } from "antd";
import { BiSolidFolder } from "react-icons/bi";

export const FolderAbout: React.FC<{ folder: FolderDto; onOpen(folder: FolderDto): Promise<void>; }> = ({ folder, onOpen: onClick }) =>
{
    return (
        <Col span={ "8" } >
            <BiSolidFolder />
            { <Typography.Link onClick={ () => onClick(folder) } style={ { marginLeft: 15 } }>{ folder.name }</Typography.Link> }
        </Col>
    );
};
