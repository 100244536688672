import { message } from "antd";
import { MessageType } from "antd/es/message/interface";
import { ApiError } from "../openapi";

export class ErrorHandler {
    public static handle(error: unknown): MessageType | undefined {
        console.error(error);
        
        if (error instanceof ApiError && error.status === 400) {
            return message.error(
                `${error.body.Message ?? "Что-то пошло не так"}. ${
                    error.body.Details ?? "Мы уже разбираемся с проблемой"
                }.`
            );
        }

        if (error instanceof ApiError && error.status === 429) {
            return message.error(
                `${
                    error.body.Message ?? "Вы сделали слишком много запросов"
                }. ${error.body.Details ?? "Попробуйте повторить запрос позже"}`
            );
        }

        if (error instanceof ApiError && error.status === 403) {
            return message.error(
                `У вас нет доступа к данному функционалу. Попробуйте войти под другим аккаунтом.`
            );
        }

        if (error instanceof ApiError && error.status === 409) {
            return message.error(
                `${error.body.Message ?? "Что-то пошло не так"}. ${
                    error.body.Details ?? "Мы уже разбираемся с проблемой"
                }`
            );
        }

        if (error instanceof ApiError && error.status === 500) {
            return message.error(
                `Упс... Произошла ошибка на сервере, попробуйте повторить запрос позже.`
            );
        }

        if (error instanceof TypeError) {
            return message.error(
                `Не удалось связаться с сервером, пожалуйста, проверьте ваше подключение к интернету.`
            );
        }
    }
}
