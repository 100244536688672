import { Button, Card, Col, Row, Space, Typography } from "antd";
import { Equipment } from "../../../services/openapi";
import { EquipmentMetricsList } from "./EquipmentMetricsList";
import { IncludedEqupmentsTabs } from "./IncludedEqupmentsTabs";

export const EquipmentRenderElement: React.FC<{ equipment: Equipment; onClose(): void; }> = ({ equipment, onClose }) =>
{
    return (<Space direction="vertical" style={ { width: '100%' } }>
        <Card>
            <Row>
                <Typography.Text style={ { color: 'var(--color-title-primary)' } }>
                    <strong>{ equipment.name }</strong>
                </Typography.Text>
            </Row>
            <Row>
                <Col span={ 8 }>
                    <Typography.Text className="description-text">
                        Номер оборудования: { equipment.objectId }
                    </Typography.Text>
                </Col>

                <Col span={ 8 }>
                    <Typography.Text className="description-text">
                        Номер линии: { equipment.lineNumber }
                    </Typography.Text>
                </Col>
            </Row>
        </Card>

        { equipment.metrics && equipment.metrics.length > 0 && <Card><EquipmentMetricsList metrics={ equipment.metrics } /></Card> }
        { equipment.equipments && equipment.equipments.length > 0 && <Card><IncludedEqupmentsTabs includedEquipments={ equipment.equipments } /></Card> }

        <Button type="dashed" onClick={ onClose }>Назад</Button>
    </Space>)
};
