import {
    Avatar,
    Button,
    Divider,
    Input,
    List,
    Skeleton,
    Space,
    Spin,
} from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FcFactory } from "react-icons/fc";
import { Link } from "react-router-dom";
import Locations from "../consts/Locations";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import {
    ProductionDtoPagedList,
    ProductionsService,
} from "../services/openapi";
import { debounce } from "lodash";

export const ProductionsPage: React.FC = () => {
    const pageSize = 10;
    const [isBusy, setBusy] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [productions, setProductions] = useState<ProductionDtoPagedList>();

    const onQueryChanged = async (event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };
    const onPageChanged = async (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        const loadProductions = async () => {
            setBusy(true);

            await ProductionsService.getApiProductions(
                query,
                (currentPage - 1) * pageSize,
                pageSize
            )
                .then(setProductions)
                .then(() => setBusy(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        };

        const debouncedFetchData = debounce(() => loadProductions(), 300);

        debouncedFetchData();

        return () => debouncedFetchData.cancel();
    }, [query, currentPage]);

    return (
        <Space
            direction="vertical"
            style={{ width: "100%", display: "flex" }}
            size={24}
        >
            <Input
                onChange={onQueryChanged}
                value={query}
                placeholder="Название производства"
            />

            <Button type="primary">
                <Link to={Locations.CreateProduction}>
                    Создать производство
                </Link>
            </Button>

            <Spin spinning={isBusy}>
                {!productions ? (
                    <Space
                        direction="vertical"
                        style={{ width: "100%", margin: "0 auto" }}
                    >
                        <Skeleton title={true} active={true} />
                        <Divider />
                        <Skeleton title={true} active={true} />
                    </Space>
                ) : (
                    <div>
                        <List
                            pagination={{
                                showLessItems: true,
                                pageSize: pageSize,
                                onChange: (pageNumber) =>
                                    onPageChanged(pageNumber),
                                total: productions.totalItems,
                            }}
                            itemLayout="horizontal"
                            dataSource={productions.items}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <IconContext.Provider
                                                value={{
                                                    color: "green",
                                                    size: "2.8em",
                                                }}
                                            >
                                                <Avatar
                                                    shape="circle"
                                                    size={48}
                                                    src={<FcFactory />}
                                                />
                                            </IconContext.Provider>
                                        }
                                        title={
                                            <Link
                                                to={`${Locations.Productions}/${item.id}`}
                                            >
                                                {item.name}
                                            </Link>
                                        }
                                        description={`${item.location?.city} +${item.location?.timeZone}`}
                                    ></List.Item.Meta>
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </Spin>
        </Space>
    );
};
