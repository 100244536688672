import {
    AutoComplete,
    Avatar,
    Button,
    Card,
    DatePicker,
    Input,
    Space,
    Tooltip,
    Typography,
} from "antd";
import { Dayjs } from "dayjs";
import { Component, ReactNode } from "react";
import { IconContext } from "react-icons";
import { FcFactory } from "react-icons/fc";
import Locations from "../consts/Locations";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import {
    AddProductionRequest,
    CitiesService,
    ProductionsService,
} from "../services/openapi";

export interface CreateProductionState {
    cityName: string;
    timeZone?: number;
    bitrixId?: number;
    productionName: string;
    foundCities: { value: string }[];
    dateStart?: Dayjs;
    isBusy: boolean;
}

export class CreateProductionPage extends Component<
    unknown,
    CreateProductionState
> {
    constructor(props: unknown) {
        super(props);

        this.state = {
            timeZone: 0,
            bitrixId: 0,
            cityName: "",
            productionName: "",
            isBusy: false,
            foundCities: [{ value: "" }],
        };
    }

    private setBusy = (busyState: boolean) =>
        this.setState({ isBusy: busyState });

    private setProductionName = (productionName: string) =>
        this.setState({ productionName: productionName });

    // private setBitrixIdentificator = (bitrixId: number) => this.setState({ bitrixId: bitrixId });

    private setOptions = (citiesNames: { value: string }[]) =>
        this.setState({ foundCities: citiesNames });

    private setTimeZone = (timeZone: number) =>
        this.setState({ timeZone: timeZone });

    private setDate = (value: Dayjs | null) =>
        this.setState({ dateStart: value ?? new Dayjs() });

    private setCity = async (cityName: string) => {
        const timeZone = await this.getLocationTimeZone(cityName);

        this.setState({ cityName: cityName });
        this.setTimeZone(timeZone);
    };

    private onCreateClicked = () => {
        const request: AddProductionRequest = {
            name: this.state.productionName,
            city: this.state.cityName,
            timeZone: this.state.timeZone!,
            // bitrixId: this.state.bitrixId!,
            dateStart: this.state.dateStart?.toISOString() ?? "",
        };

        ProductionsService.postApiProductions(request)
            .then(() => window.location.assign(Locations.Productions))
            .catch(ErrorHandler.handle);
    };

    private getLocationTimeZone = async (cityName: string): Promise<number> => {
        try {
            const cities = await CitiesService.getApiCities(cityName);
            const city = cities.find((c) => c.name == cityName);
            const timeZone = await CitiesService.getApiCitiesTimeZone(
                city?.lat,
                city?.lng
            );

            return timeZone.gmtOffset ?? 0;
        } catch {
            return 0;
        }
    };

    private onSearchCities = async (
        query: string
    ): Promise<{ value: string }[]> => {
        try {
            this.setBusy(true);

            this.setState({ cityName: query });

            const cities = await CitiesService.getApiCities(query);
            const citiesNames = cities.map((city) => city.name);
            const options = citiesNames
                .filter((value, index, self) => self.indexOf(value) === index)
                .map((name) => ({ value: name! }));

            this.setBusy(false);

            return options;
        } catch (error) {
            ErrorHandler.handle(error);
            this.setBusy(false);
            return [];
        }
    };

    render(): ReactNode {
        return (
            <div className="user-container">
                <Typography.Title level={3}>
                    Регистрация нового производства
                </Typography.Title>
                <Typography.Text className="description-text">
                    Заполните все данные для нового производства
                </Typography.Text>
                <Space
                    direction="vertical"
                    style={{ width: "100%", marginTop: 20 }}
                    size={26}
                >
                    <Card>
                        <Space
                            style={{ width: "100%" }}
                            size={24}
                            direction="vertical"
                        >
                            <Card>
                                <Space align="center">
                                    <IconContext.Provider
                                        value={{ color: "green", size: "3em" }}
                                    >
                                        <Avatar
                                            shape="square"
                                            size={55}
                                            src={<FcFactory />}
                                        />
                                    </IconContext.Provider>
                                    <Space direction="vertical">
                                        <Typography.Title
                                            style={{
                                                margin: "auto auto",
                                                color: "#1677FF",
                                            }}
                                            level={5}
                                        >
                                            {this.state.productionName &&
                                            this.state.productionName?.length >
                                                0
                                                ? this.state.productionName
                                                : "Название производства"}
                                        </Typography.Title>

                                        <Typography.Text className="description-text">
                                            {this.state.cityName &&
                                            this.state.cityName?.length > 0
                                                ? `${this.state.cityName} +${this.state.timeZone}:00`
                                                : "Город производства"}
                                        </Typography.Text>
                                    </Space>
                                </Space>
                            </Card>

                            <Input
                                required={true}
                                value={this.state.productionName}
                                onChange={(e) =>
                                    this.setProductionName(e.target.value)
                                }
                                placeholder="Название производства"
                            ></Input>

                            {/* <Tooltip
                            color="geekblue"
                            placement="topLeft"
                            title="
                            Идентификатор компании, которому принадлежит производство. 
                            Вы можете найти его в Bitrix24 или спросить у менеджера.">
                            <Input
                                type="number"
                                onChange={ e => this.setBitrixIdentificator(
                                    Number.parseInt(e.target.value)) }
                                placeholder="Идентификатор компании в Bitrix24"></Input>
                        </Tooltip> */}

                            <DatePicker
                                format={"DD.MM.YYYY"}
                                placeholder="Дата запуска производства"
                                style={{ width: "100%" }}
                                onChange={(e) => this.setDate(e)}
                            ></DatePicker>

                            <AutoComplete
                                style={{ width: "100%" }}
                                value={this.state.cityName}
                                options={this.state.foundCities}
                                onSelect={this.setCity}
                                onSearch={async (text) =>
                                    this.setOptions(
                                        await this.onSearchCities(text)
                                    )
                                }
                                placeholder="Город производства"
                            ></AutoComplete>

                            <Tooltip
                                color="geekblue"
                                placement="topLeft"
                                title="Часовой пояс производства. 
                            Заполняется автоматически. 
                            Но при отклонения вы можете настроить его сами."
                            >
                                <Input
                                    value={this.state.timeZone}
                                    onChange={(e) =>
                                        this.setTimeZone(
                                            Number.parseInt(e.target.value)
                                        )
                                    }
                                    type="number"
                                    min={-12}
                                    max={14}
                                    style={{ width: "100%" }}
                                    placeholder="Часовой пояс"
                                ></Input>
                            </Tooltip>
                            <Button
                                onClick={this.onCreateClicked}
                                type="primary"
                                disabled={this.state.isBusy}
                            >
                                Создать производство
                            </Button>
                        </Space>
                    </Card>
                </Space>
            </div>
        );
    }
}
