import { ChangeEvent, useEffect, useState } from "react";
import {
    CreateFolderRequest,
    FileDtoPagedList,
    FolderDto,
    ProductionsService,
} from "../../../services/openapi";
import {
    Button,
    Divider,
    Input,
    Modal,
    Space,
    Spin,
    Typography,
    message,
} from "antd";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import Dragger from "antd/es/upload/Dragger";
import { AiOutlineInbox } from "react-icons/ai";
import { AppConfiguration } from "../../../configurations/AppConfiguration";
import { IconContext } from "react-icons";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { DocumentsList } from "./DocumentsList";
import { FoldersList } from "./FoldersList";
import { BackFolder } from "./BackFolder";
import { useAppSelector } from "../../../hooks/hook";

export const ProductionDocuments: React.FC = () => {
    const production = useAppSelector(
        (state) => state.productionState.production!
    );

    const [folder, setFolder] = useState<FolderDto>();
    const [uploadMode, setUploadMode] = useState<boolean>(false);
    const [isBusy, setBusy] = useState<boolean>(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [folderName, setFolderName] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [foundFiles, setFoundFiles] = useState<FileDtoPagedList>();

    const onUploadModeClick = () => setUploadMode(!uploadMode);

    const onFolderNameChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setFolderName(event.target.value);

    const onUploadChanged = async (info: UploadChangeParam<UploadFile>) => {
        const { status } = info.file;

        if (status === "done") {
            message.success(`${info.file.name} файл успешно загружен.`);
            await loadFolder(folder);
        } else if (status === "error") {
            message.error(`${info.file.name} не удалось загрузить файл.`);
        }
    };

    const onCreateFolderClicked = async () => {
        const request: CreateFolderRequest = {
            parentId: folder!.id,
            name: folderName,
        };

        setBusy(true);

        await ProductionsService.postApiProductionsFolders(
            production.id,
            request
        )
            .then(async () => await onOpen(folder))
            .then(() => setModalOpen(false))
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    const onOpen = async (folder: FolderDto | undefined) => {
        if (folder) {
            setBusy(true);

            await ProductionsService.getApiProductionsFolders(
                production.id,
                folder.id
            )
                .then(setFolder)
                .then(() => setBusy(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }
    };

    const onSearchQueryChanged = async (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setSearchQuery(event.target.value);

        const searchFilesOffset = 0;
        const searchFilesQuantity = 30;

        if (event.target.value) {
            setBusy(true);

            await ProductionsService.getApiProductionsFiles(
                production.id,
                event.target.value,
                searchFilesOffset,
                searchFilesQuantity
            )
                .then(setFoundFiles)
                .then(() => setBusy(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }
    };

    const loadFolder = async (folder?: FolderDto): Promise<void> => {
        setBusy(true);

        await ProductionsService.getApiProductionsFolders(
            production.id,
            folder?.id
        )
            .then(setFolder)
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    useEffect(() => {
        loadFolder();
    }, [production.id]);

    return (
        <Spin spinning={isBusy}>
            <Space direction="vertical" style={{ width: "100%" }}>
                {!uploadMode && (
                    <Space
                        direction="vertical"
                        style={{ width: "100%", marginTop: 15 }}
                    >
                        <Space>
                            <Input
                                value={searchQuery}
                                onChange={onSearchQueryChanged}
                                placeholder="Поиск по документам"
                            ></Input>

                            <Button
                                style={{ right: 0 }}
                                onClick={onUploadModeClick}
                                type="primary"
                            >
                                Загрузить
                            </Button>
                            <Button
                                style={{ right: 0 }}
                                onClick={() => setModalOpen(true)}
                                type="primary"
                            >
                                Создать папку
                            </Button>
                        </Space>

                        <Divider />

                        {searchQuery.length != 0 && foundFiles && (
                            <IconContext.Provider
                                value={{
                                    color: "#B1B1B1",
                                    size: "15",
                                    style: { margin: "-2px auto" },
                                }}
                            >
                                <DocumentsList
                                    production={production}
                                    documents={foundFiles.items}
                                    onChange={loadFolder}
                                />
                            </IconContext.Provider>
                        )}

                        {searchQuery.length == 0 && folder && (
                            <Space
                                direction="vertical"
                                style={{ width: "100%" }}
                            >
                                <IconContext.Provider
                                    value={{
                                        color: "#1677ff",
                                        size: "15",
                                        style: { margin: "-4px auto" },
                                    }}
                                >
                                    <BackFolder
                                        currentFolder={folder}
                                        onOpen={async () =>
                                            await onOpen(folder?.parentFolder)
                                        }
                                    />
                                    <FoldersList
                                        production={production}
                                        folders={folder.folders}
                                        onChange={loadFolder}
                                        onOpen={onOpen}
                                    ></FoldersList>
                                </IconContext.Provider>
                                <IconContext.Provider
                                    value={{
                                        color: "#B1B1B1",
                                        size: "15",
                                        style: { margin: "-2px auto" },
                                    }}
                                >
                                    <DocumentsList
                                        production={production}
                                        documents={folder.documents}
                                        onChange={loadFolder}
                                    />
                                </IconContext.Provider>
                            </Space>
                        )}
                    </Space>
                )}

                {uploadMode && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Dragger
                            name="formFile"
                            multiple
                            method="POST"
                            withCredentials
                            onChange={onUploadChanged}
                            action={`${AppConfiguration.GetBaseAddress()}/api/productions/${
                                production.id
                            }/files?folderId=${folder?.id}`}
                        >
                            <p className="ant-upload-drag-icon">
                                <IconContext.Provider
                                    value={{ color: "gray", size: "3rem" }}
                                >
                                    <AiOutlineInbox />
                                </IconContext.Provider>
                            </p>
                            <p className="ant-upload-text">
                                Нажмите или переместите файлы сюда для загрузки.
                            </p>
                            <p className="ant-upload-hint">
                                Поддерживаются все типы файлов
                            </p>
                        </Dragger>

                        <Button type="dashed" onClick={onUploadModeClick}>
                            Вернуться назад
                        </Button>
                    </Space>
                )}

                <Modal
                    open={isModalOpen}
                    title="Новая папка"
                    okText="Создать папку"
                    onCancel={() => setModalOpen(false)}
                    onOk={onCreateFolderClicked}
                    cancelText="Отменить"
                >
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Typography.Text>
                            Для создания папки укажите имя.
                        </Typography.Text>
                        <Input
                            value={folderName}
                            onChange={onFolderNameChanged}
                            placeholder="Название папки"
                        ></Input>
                    </Space>
                </Modal>
            </Space>
        </Spin>
    );
};
