import { Collapse } from "antd";
import { Metric, ValueUnitType } from "../../../services/openapi";
import { MetricsValuesChart } from "./MetricsValuesChart";


export const EquipmentMetricsList: React.FC<{ metrics: Metric[]; }> = ({ metrics }) =>
{
    const metricsOptions = metrics.map((item, index) => ({
        key: index,
        label: `${ item.name } ${ item.valueUnitType === ValueUnitType.COUNTER ? 'Счётчик' : 'Моточасы' }`,
        children: <MetricsValuesChart metric={ item } />
    }));

    return <Collapse ghost items={ metricsOptions } />;
};
