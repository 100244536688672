/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddLicenseKeyRequest } from '../models/AddLicenseKeyRequest';
import type { LicenseKeyDto } from '../models/LicenseKeyDto';
import type { LicenseKeyDtoPagedList } from '../models/LicenseKeyDtoPagedList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicensesService {

    /**
     * @param id 
     * @returns LicenseKeyDto Success
     * @throws ApiError
     */
    public static getApiLicenses(
id: string,
): CancelablePromise<LicenseKeyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Licenses/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiLicenses(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Licenses/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param productionId 
     * @returns LicenseKeyDto Success
     * @throws ApiError
     */
    public static getApiLicensesLastCreated(
productionId?: string,
): CancelablePromise<LicenseKeyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Licenses/LastCreated',
            query: {
                'productionId': productionId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param productionId 
     * @param offset 
     * @param quantity 
     * @returns LicenseKeyDtoPagedList Success
     * @throws ApiError
     */
    public static getApiLicenses1(
productionId?: string,
offset?: number,
quantity?: number,
): CancelablePromise<LicenseKeyDtoPagedList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Licenses',
            query: {
                'productionId': productionId,
                'offset': offset,
                'quantity': quantity,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns LicenseKeyDto Success
     * @throws ApiError
     */
    public static postApiLicenses(
requestBody?: AddLicenseKeyRequest,
): CancelablePromise<LicenseKeyDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Licenses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
