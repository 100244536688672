import { Col, Divider, Row, Typography } from "antd";
import { ComponentDto } from "../../../services/openapi";

export const ComponentListItem: React.FC<{
    component: ComponentDto;
    onClick(component: ComponentDto): void;
}> = ({ component, onClick }) => {
    return (
        <Row>
            <Col xl={8} xs={24}>
                <Typography.Link onClick={() => onClick(component)}>
                    <strong>{component.name}</strong>
                </Typography.Link>
            </Col>
            <Col xl={8} xs={24}>
                {component.metric?.name}
            </Col>
            <Col xl={8} xs={24}>
                {component.metric?.valueUnitType}
            </Col>
            <Divider />
        </Row>
    );
};
