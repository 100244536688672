import { Avatar, Space, Tabs, TabsProps, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import { UserDto, UsersService } from "../services/openapi";
import { UserAccess } from "../components/user/access/UserAccess";
import { UserContacts } from "../components/user/contacts/UserContacts";

export const UserPage: React.FC = () => {
    const { id } = useParams();
    const [user, setUser] = useState<UserDto>();
    const [isBusy, setBusy] = useState<boolean>(false);

    const items: TabsProps["items"] = [
        {
            key: "contact-data",
            label: "Контактные данные",
            children: <UserContacts user={user!} />,
        },
        {
            key: "access-data",
            label: "Доступ",
            children: <UserAccess user={user!} />,
        },
    ];

    useEffect(() => {
        async function loadUser(): Promise<void> {
            setBusy(true);

            await UsersService.getApiUsers1(id!)
                .then(setUser)
                .catch(ErrorHandler.handle);

            setBusy(false);
        }

        loadUser();
    }, []);

    if (!isBusy) {
        return (
            <Space direction="vertical" style={{ width: "100%" }} size={26}>
                <Space align="center">
                    <Avatar
                        size={55}
                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`}
                    />
                    <Space direction="vertical">
                        <Typography.Title
                            style={{
                                margin: "auto auto",
                                color: "var(--color-title-primary)",
                            }}
                            level={5}
                        >
                            {user?.fullname}
                        </Typography.Title>

                        <Typography.Text className="description-text">
                            {user?.email}
                        </Typography.Text>
                    </Space>
                </Space>

                {user && <Tabs items={items} />}
            </Space>
        );
    }
};

export default UserPage;
