import { AutoComplete, Avatar, Button, Card, Divider, Input, Space, Spin, Typography, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { AddEmployeeRequest, EmployeeDto, EmployeeType, EmployeesService, ProductionsService } from "../../../../services/openapi";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import Select, { DefaultOptionType } from "antd/es/select";
import { ChangeEvent, useState } from "react";
import { setProduction } from "../../../../store/productionSlice";

const CreateEmployeeRenderElement: React.FC<{ onClick(): void }> = ({ onClick }) =>
{
    const [isBusy, setBusy] = useState<boolean>(false);
    const [fullName, setFullname] = useState<string>('');
    const [department, setDepartment] = useState<string>('');
    const [contact, setContact] = useState<string>('');
    const [type, setType] = useState<EmployeeType>(EmployeeType.DEVELOPER);

    function onFullnameChanged(event: ChangeEvent<HTMLInputElement>) { setFullname(event.target.value); }
    function onDepartmentChanged(event: ChangeEvent<HTMLInputElement>) { setDepartment(event.target.value); }
    function onContactChanged(event: ChangeEvent<HTMLInputElement>) { setContact(event.target.value); }
    function onTypeChanged(type: EmployeeType) { setType(type); }

    async function onCreateClicked() 
    {
        setBusy(true);

        const request: AddEmployeeRequest = {
            name: fullName,
            contact: {
                phone: contact,
                department: department,
            },
            employeeType: type
        };

        await EmployeesService
            .postApiEmployees(request)
            .then(() => setBusy(false))
            .then(onClick)
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    }

    return (
        <Spin spinning={ isBusy }>
            <Space direction="vertical" style={ { width: '100%' } }>
                <Input onChange={ onFullnameChanged } value={ fullName } placeholder="ФИО"></Input>
                <Input onChange={ onDepartmentChanged } value={ department } placeholder="Отдел"></Input>
                <Input onChange={ onContactChanged } value={ contact } placeholder="Почтовый адрес / номер телефона"></Input>
                <Select onChange={ onTypeChanged } style={ { width: '100%' } } value={ type } options={ [{
                    label: 'Разработчик',
                    value: EmployeeType.DEVELOPER
                },
                {
                    label: 'Инженер ПНР',
                    value: EmployeeType.ENGINEER
                },
                {
                    label: 'Менеджер',
                    value: EmployeeType.MANAGER
                }] }></Select>

                <Space>
                    <Button onClick={ onClick } type="dashed">Отменить</Button>
                    <Button onClick={ onCreateClicked } type="primary">Создать</Button>
                </Space>
            </Space>
        </Spin>);
}

const EmployeeRenderElement: React.FC<{ employee: EmployeeDto }> = ({ employee }) =>
{
    return (<Card>
        <Space align="center">
            <Avatar size={ 55 } src={ `https://xsgames.co/randomusers/avatar.php?g=pixel` } />
            <Space direction="vertical">
                <Typography.Title style={ { margin: "auto auto", color: "#1677FF" } } level={ 5 }>
                    { employee?.name }
                </Typography.Title>
                <Space>
                    <Typography.Text className="description-text">
                        { employee?.phone }
                    </Typography.Text>
                    <Divider type="vertical" />
                    <Typography.Text className="description-text">
                        { employee?.department }
                    </Typography.Text>
                </Space>
            </Space>
        </Space>
    </Card>);
}

export const ProductionEmployeesEdit: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const dispatch = useAppDispatch();
    const production = useAppSelector(state => state.productionState.production!);

    const [isBusy, setBusy] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchOptions, setSearchOptions] = useState<DefaultOptionType[]>([]);
    const [employee, setEmployee] = useState<EmployeeDto>();
    const [isCreateMode, setCreateMode] = useState<boolean>(false);

    const onSelectionChanged = async (employeeId: string) =>
    {
        setSearchQuery('');

        setBusy(true);

        await EmployeesService
            .getApiEmployees1(employeeId)
            .then(setEmployee)
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle);
    };

    const updateProduction = async () =>
    {
        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onSaveClicked = async () =>
    {
        setBusy(true);

        await ProductionsService
            .postApiProductionsEmployees(production.id, employee!.id)
            .then(async () => await updateProduction())
            .then(() => setBusy(false))
            .then(() => onClick())
            .then(() => message.success("Сотрудник успешно добавлен"))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    const onSearchQueryChanged = async (event: string) =>
    {
        setSearchQuery(event);

        setBusy(true);

        await EmployeesService
            .getApiEmployees(searchQuery, 0, 10)
            .then(result => result.items?.map<DefaultOptionType>(x => ({ label: x.name, value: x.id })))
            .then(mappedEntities => mappedEntities && setSearchOptions(mappedEntities))
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    if (isCreateMode)
    {
        return <CreateEmployeeRenderElement onClick={ () => setCreateMode(false) } />;
    }
    else
    {
        return <Spin spinning={ isBusy }>
            <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
                <AutoComplete style={ { width: "100%" } } onSelect={ onSelectionChanged } value={ searchQuery } options={ searchOptions } onChange={ onSearchQueryChanged } placeholder="Начните ввод для поиска"></AutoComplete>
                { employee &&
                    <Space direction="vertical" style={ { width: "100%" } }>
                        <EmployeeRenderElement employee={ employee } />

                        <Space>
                            <Button type="dashed" onClick={ onClick }>Вернуться назад</Button>
                            <Button type="primary" onClick={ onSaveClicked }>Выбрать сотрудника</Button>
                        </Space>
                    </Space> }

                { employee == null &&
                    <Space>
                        <Button type="dashed" onClick={ onClick }>Отменить</Button>
                        <Button type="primary" onClick={ () => setCreateMode(true) }>Создать работника</Button>
                    </Space>
                }
            </Space>
        </Spin>;
    }
};
