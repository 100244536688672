import { Avatar, Col, Tooltip, Typography } from "antd";
import React from "react";
import { OwnerDto } from "../../../services/openapi";


export const DocumentOwner: React.FC<{ user?: OwnerDto; }> = ({ user }) =>
{
    if (user)
    {
        return (
            <Col span={ 4 }>
                <Tooltip title="Автор файла" color="geekblue">
                    <Avatar size={ 32 } src={ `https://xsgames.co/randomusers/avatar.php?g=pixel&key=1` } />
                    <Typography.Text className="description-text">{ user.fullname }</Typography.Text>
                </Tooltip>
            </Col>);
    }

    return (
        <Col span={ 4 }>
            <Avatar size={ 32 } src={ `https://xsgames.co/randomusers/avatar.php?g=pixel&key=1` } />
            <Typography.Text className="description-text">Пользователь удален</Typography.Text>
        </Col>);
};
