import { configureStore } from "@reduxjs/toolkit"
import productionReducer from './productionSlice';

const store = configureStore({
    reducer: {
        productionState: productionReducer
    },
})

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;