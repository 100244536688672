import React from "react";
import { FileDto } from "../../../services/openapi";
import { Col, Tooltip, Typography } from "antd";

export const DocumentEditDate: React.FC<{ document: FileDto }> = ({
    document,
}) => {
    return (
        <Col
            span={6}
            style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
            }}
        >
            {document.editedAt && (
                <Tooltip
                    color="geekblue"
                    title={`Дата последнего изменения: ${new Date(
                        document.editedAt
                    ).toLocaleString()}`}
                >
                    <Typography.Text className="description-text">
                        {`${new Date(document.editedAt).toLocaleString()}`}
                    </Typography.Text>
                </Tooltip>
            )}
        </Col>
    );
};
