import { Button, Card, Col, Divider, Empty, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { ApplicationSettingsDto, ProductionsService } from "../../../../services/openapi";
import { setProduction } from "../../../../store/productionSlice";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { AiOutlineDelete } from "react-icons/ai";


export const ProductionApplicationsInfo: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const [isBusy, setBusy] = useState<boolean>(false);

    const updateProduction = async () =>
    {
        if (!production.id) throw new Error();

        await ProductionsService
            .getApiProductions1(production.id)
            .then(result => dispatch(setProduction(result)))
            .catch(ErrorHandler.handle);
    };

    const onDeleteApplicationClicked = async (application: ApplicationSettingsDto) =>
    {
        if (!production.id) throw new Error();
        if (!application.id) throw new Error();

        setBusy(true);

        await ProductionsService
            .deleteApiProductionsApplicationSettings(production.id, application.id)
            .then(async () => await updateProduction())
            .then(() => setBusy(false))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    return <Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: "100%" } }>
            { Object.keys(production?.applicationSettings ?? {}).length != 0 ?
                <Space style={ { width: "100%" } } direction="vertical">
                    { production.applicationSettings?.map(application =>
                    {
                        return <Card key={ application.id } title={
                            <Space>
                                <Typography.Title style={ { margin: "auto auto", color: "#1677FF" } } level={ 5 }>
                                    { application?.applicationType }
                                </Typography.Title>
                            </Space>
                        } extra={
                            <Tooltip title="Удалить ПО" color="geekblue">
                                <Popconfirm okText="Да, удалить" onConfirm={ () => onDeleteApplicationClicked(application) } cancelText="Нет" title="Вы уверены что хотите удалить это программное обеспечение?">
                                    <Button type="link" danger><AiOutlineDelete /></Button>
                                </Popconfirm>
                            </Tooltip> } style={ { width: "100%" } }>
                            <Space align="center" style={ { width: "100%" } }>
                                <Space direction="vertical" style={ { width: "100%" } }>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Typography.Text className="description-text">
                                                Идентификатор: { application?.pcIdentificator }
                                            </Typography.Text>

                                            <Divider type="vertical" />

                                            <Typography.Text className="description-text">
                                                IP адрес: { application?.ipAddress }
                                            </Typography.Text>

                                            <Divider type="vertical" />

                                            <Typography.Text className="description-text">
                                                Имя компьютера: { application?.machineName }
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </Space>
                            </Space>

                        </Card>;
                    }) }
                    <Button type="primary" onClick={ onClick }>Добавить</Button>
                </Space>
                :
                <Empty description="Ещё нет информации о ПО">
                    <Button type="primary" onClick={ onClick }>Добавить</Button>
                </Empty> }
        </Space>
    </Spin>;
};
