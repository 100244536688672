import { ChangeEvent, useEffect, useState } from "react";
import { ProductionsService, UpdateServerSettingsRequest } from "../../../../services/openapi";
import { Button, Card, Divider, Empty, Input, Space, Spin, Typography } from "antd";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setProduction } from "../../../../store/productionSlice";

export const ServerSettings: React.FC = () =>
{
    const dispatch = useAppDispatch();
    const production = useAppSelector(state => state.productionState.production);
    const [isEdit, setEdit] = useState<boolean>(false);

    const [isBusy, setBusy] = useState<boolean>(false);
    const [identificator, setIdentificator] = useState<string>('');
    const [machine, setMachine] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const onMachineChanged = (event: ChangeEvent<HTMLInputElement>) => setMachine(event.target.value);
    const onAddressChanged = (event: ChangeEvent<HTMLInputElement>) => setAddress(event.target.value);
    const onIdentificatorChanged = (event: ChangeEvent<HTMLInputElement>) => setIdentificator(event.target.value);

    const updateProduction = async () =>
    {
        if (production)
        {
            await ProductionsService
                .getApiProductions1(production.id)
                .then(result => dispatch(setProduction(result)))
                .catch(ErrorHandler.handle);
        }
    }

    const onUpdateClicked = async () =>
    {
        const request: UpdateServerSettingsRequest = {
            ipAddress: address,
            machineName: machine,
            pcIdentificator: identificator
        };

        if (production)
        {
            setBusy(true);

            await ProductionsService
                .putApiProductionsServerSettings(production.id, request)
                .then(async () => await updateProduction())
                .then(() => setEdit(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }
    }

    useEffect(() =>
    {
        setIdentificator(production?.serverSettings?.pcIdentificator ?? '');
        setAddress(production?.serverSettings?.ipAddress ?? '');
        setMachine(production?.serverSettings?.machineName ?? '');
    }, [production?.serverSettings?.pcIdentificator, production?.serverSettings?.ipAddress, production?.serverSettings?.machineName]);

    return (
        <Space direction="vertical" style={ { width: "100%" } }>
            <Typography.Title level={ 5 }>
                Информация о сервере
            </Typography.Title>
            <Typography.Text className="description-text">
                Здесь вы можете посмотреть или изменить информацию о сервере на стороне клиента, некоторые данные используется для аутентификации и при выдаче лицензии.
            </Typography.Text>
            <Divider />

            { !isEdit ?
                <Space direction="vertical" style={ { width: "100%" } }>
                    { production?.serverSettings ?
                        <Space direction="vertical" style={ { width: "100%" } }>

                            <Card>
                                <Space direction="vertical" style={ { width: "100%" } }>
                                    <Typography.Title style={ { margin: "auto auto", color: "#1677FF" } } level={ 5 }>BSU_server</Typography.Title>

                                    <Space>
                                        <Typography.Text className="description-text">Идентификатор: { production?.serverSettings.pcIdentificator }</Typography.Text>
                                        <Divider type="vertical" />
                                        <Typography.Text className="description-text">Имя компьютера: { production?.serverSettings.machineName }</Typography.Text>
                                        <Divider type="vertical" />
                                        <Typography.Text className="description-text">IP адрес: { production?.serverSettings.ipAddress }</Typography.Text>
                                    </Space>
                                </Space>
                            </Card>

                            <Button
                                onClick={ () => setEdit(true) }
                                style={ { margin: "0 auto", display: "absolute" } } type="primary">Изменить</Button>

                        </Space>
                        :
                        <Empty description="Пока что нет данных о сервере вы можете их заполнить вручную" style={ { margin: "0 auto" } }>
                            <Button
                                onClick={ () => setEdit(true) }
                                style={ { margin: "0 auto", display: "absolute" } } type="primary">Заполнить</Button>
                        </Empty>
                    }
                </Space> :
                <Spin spinning={ isBusy }>
                    <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
                        <Input
                            onChange={ onIdentificatorChanged }
                            value={ identificator ?? "" }
                            placeholder="Идентификатор компьютера"></Input>

                        <Input
                            onChange={ onMachineChanged }
                            value={ machine ?? "" }
                            placeholder="Имя компьютера"></Input>

                        <Input
                            onChange={ onAddressChanged }
                            value={ address ?? "" }
                            placeholder="IP адрес"></Input>

                        <Space>
                            <Button
                                onClick={ () => setEdit(false) }
                                type="dashed">Отменить</Button>
                            <Button
                                onClick={ onUpdateClicked }
                                style={ { margin: "0 auto", display: "absolute" } } type="primary">Сохранить</Button>
                        </Space>
                    </Space>
                </Spin> }
        </Space>);
}

export default ServerSettings;