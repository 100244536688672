import { Tabs } from "antd";
import { IncludedEquipment } from "../../../services/openapi";
import { IncludedEquipmentMetricsList } from "./IncludedEquipmentMetricsList";


export const IncludedEqupmentsTabs: React.FC<{ includedEquipments: IncludedEquipment[]; }> = ({ includedEquipments }) =>
{
    return <Tabs items={ includedEquipments.map(item => ({
        key: item.id!,
        label: item.name,
        value: item.id,
        children: <IncludedEquipmentMetricsList includedEquipment={ item } />
    })) } />;
};
