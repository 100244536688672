import { AuthorizationService } from "../../services/openapi";
import { AuthorizationState } from "../../services/openapi/models/AuthorizationState";

export class AuthStore {
    static isBusy = false;
    static isRefreshing = false;
    static isExpired = false;

    static getAuthorizationState =
        async (): Promise<AuthorizationState | null> => {
            try {
                this.isBusy = true;
                const state =
                    await AuthorizationService.getAuthorizationState();
                this.isBusy = false;
                return state;
            } catch {
                this.isBusy = false;
                return null;
            }
        };

    static handleExpiredToken = () => this.isExpired = true;

    static refreshJwtToken = async (): Promise<void> => {
        if (this.isRefreshing) {
            await this.waitForRefresh();
        }

        if (!this.isRefreshing && this.isExpired) {
            this.isRefreshing = true;

            await AuthorizationService
                .postApiAuthorizationRefresh();

            this.isExpired = false;
            this.isRefreshing = false;
        }
    };

    static waitForRefresh = async (): Promise<void> => {
        while (this.isRefreshing)
            await new Promise((resolve) => {
                setTimeout(resolve, 1000);
            });
    };
}

export default AuthStore;
