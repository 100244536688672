import React from "react";
import { FileDto } from "../../../services/openapi";
import { Col, Typography } from "antd";
import { DocumentsProvider } from "./DocumentsIconProvider";

export const DocumentAbout: React.FC<{ document: FileDto, hrefToDownload: string }> = ({ document, hrefToDownload: href }) =>
{
    return (
        <Col span={ "8" } style={ { overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" } }>
            { DocumentsProvider.getIcon(document.name!) }
            { <Typography.Link href={ href } style={ { marginLeft: 15 } }>{ document.name }</Typography.Link> }
        </Col>
    );
};