/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RemoteApplicationType {
    ANY_DESK = 'AnyDesk',
    RUST_DESK = 'RustDesk',
    MICROSOFT_REMOTE = 'MicrosoftRemote',
    TEAM_VIEWER = 'TeamViewer',
}
