import { Col, List, Row } from "antd";
import { FolderDto, ProductionDto } from "../../../services/openapi";
import { DocumentOwner } from "./DocumentAuthor";
import { FolderActions } from "./FolderActions";
import { FolderAbout } from "./FolderAbout";
import { FolderEditDate } from "./FolderEditDate";


export const FoldersList: React.FC<{ production: ProductionDto; folders: FolderDto[]; onChange(): Promise<void>; onOpen(folder: FolderDto): Promise<void>; }> = ({ production, folders, onChange, onOpen }) =>
{
    if (folders.length != 0)
    {
        return (<List
            split
            dataSource={ folders }
            renderItem={ (item) =>
            {
                return (
                    <Row align={ "middle" }>
                        <FolderAbout folder={ item } onOpen={ onOpen } />
                        <Col span={ 1 } />
                        <DocumentOwner user={ item.owner } />
                        <FolderEditDate folder={ item } />
                        <Col span={ 4 } />
                        <FolderActions folder={ item } onChange={ onChange } production={ production } />
                    </Row>
                );
            } }>
        </List>);
    }
};
