import { Divider, Space, Typography } from "antd"
import { useState } from "react"
import { ProductionRemoteInfo } from "./ProductionRemoteInfo";
import { ProductionRemoteEdit } from "./ProductionRemoteEdit";

export const ProductionRemote: React.FC = () => 
{
    const [isEdit, setEdit] = useState<boolean>(false);

    return (<Space direction="vertical" style={ { width: "100%" } }>
        <Typography.Title level={ 5 }>
            Информация о программах для удаленного подключения
        </Typography.Title>
        <Typography.Text className="description-text">
            Здесь вы можете посмотреть или изменить информацию программах для удаленного подключения на стороне клиента.
        </Typography.Text>
        <Divider />

        { isEdit && <ProductionRemoteEdit onClick={ () => setEdit(false) } /> }
        { !isEdit && <ProductionRemoteInfo onClick={ () => setEdit(true) } /> }
    </Space>)
}