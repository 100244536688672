import { DatePicker, Divider, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { Metric, MetricValue, MetricsService } from "../../../services/openapi";
import { ValuesData } from "../ValuesData";
import dayjs from "dayjs";

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0]

export const MetricsValuesChart: React.FC<{ metric: Metric; }> = ({ metric }) =>
{
    const [isBusy, setBusy] = useState<boolean>(false);
    const [values, setValues] = useState<ValuesData[]>();
    const [dateRange, setDateRange] = useState<RangeValue>([dayjs().add(-30, 'day'), dayjs()]);

    function onDateChange(dateRange: RangeValue)
    {
        setDateRange(dateRange);
    }

    const mapValues = (values: MetricValue[]): ValuesData[] =>
    {
        const valuesData = values.map<ValuesData>(item => ({
            name: new Date(item.snapshotTime!).toLocaleString(),
            date: new Date(item.snapshotTime!),
            uv: item.value!,
            mv: 0
        }));

        const sortedValues = valuesData.sort((prev, next) => {
            if (prev.date < next.date) {
                return -1;
            }

            if (prev.date > next.date) {
                return 1;
            }

            return 0;
        });

        return sortedValues;
    }

    useEffect(() =>
    {
        async function loadValues()
        {
            if (!dateRange) return;

            setBusy(true);

            await MetricsService
                .getApiMetricsValues(metric.id, dateRange[0]?.toJSON(), dateRange[1]?.toJSON())
                .then(values => setValues(mapValues(values)))
                .then(() => setBusy(false))
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }

        loadValues();
    }, [dateRange]);

    return <Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: '100%' } } size={ 24 }>
            <Divider />
            <Space>
                <Typography.Text>Выберите дату для отчёта:</Typography.Text>
                <DatePicker.RangePicker format='DD.MM.YYYY' placeholder={ ['От', 'До'] } onChange={ onDateChange } value={ dateRange } style={ { width: '100%' } } />
            </Space>
            <Divider />
            <ResponsiveContainer
                width='100%' height='100%' minHeight={ 300 }>
                <LineChart
                    width={ 500 }
                    height={ 300 }
                    data={ values }
                >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line dataKey="uv" name="Значение" />
                </LineChart>
            </ResponsiveContainer>
        </Space>
    </Spin>;
};
