import React from "react";
import { FileDto } from "../../../services/openapi";
import { Col, Tooltip } from "antd";
import { IconContext } from "react-icons";
import { BiInfoCircle } from "react-icons/bi";


export const DocumentDescription: React.FC<{ document: FileDto; }> = ({ document }) =>
{
    return (
        <Col span={ 1 }>
            { document.description && document.description.length != 0 &&
                <IconContext.Provider value={ { color: "#1677ff", size: "18", style: { margin: "8px auto" } } }>
                    <Tooltip title={ document.description } color="geekblue">
                        <BiInfoCircle />
                    </Tooltip>
                </IconContext.Provider> }
        </Col>
    );
};



