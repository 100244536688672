import { Button, Col, Input, Row, Space, Spin, TreeSelect } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hook";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import {
    ComponentDto,
    ComponentsService,
    Equipment,
    MetricsService,
    ValueUnitType,
} from "../../../services/openapi";
import { UpdateComponentRequest } from "../../../services/openapi/models/UpdateComponentRequest";
import { MetricOptionType } from "./ComponentCreateView";
import { Guid } from "js-guid";

export const ComponentEditView: React.FC<{
    component: ComponentDto;
    onClose(): void;
    onEdit(editModel: boolean): void;
    onComponentUpdate(component: ComponentDto): void;
}> = ({ component, onEdit, onComponentUpdate }) => {
    const production = useAppSelector(
        (state) => state.productionState.production!
    );
    const [maxValue, setMaxValue] = useState<number>(component.maxValue!);
    const [isBusy, setBusy] = useState<boolean>(false);
    const [selectedMetric, setSelectedMetric] = useState<MetricOptionType>({
        value: [component.metric?.id, Guid.newGuid()].join("_"),
        label: component.metric?.name,
    });
    const [equipmentsOptions, setEquipmentsOptions] = useState<
        MetricOptionType[]
    >([]);

    const onMaxValueChanged = (event: ChangeEvent<HTMLInputElement>): void =>
        setMaxValue(Number.parseFloat(event.target.value));

    const onMetricChange = (metricOption: MetricOptionType): void =>
        setSelectedMetric(metricOption);

    const translateUnitType = (valueUnitType: ValueUnitType): string =>
        valueUnitType == ValueUnitType.COUNTER ? "Счётчик" : "Моточасы";

    function mapEquipments(equipments: Equipment[]): MetricOptionType[] {
        return equipments?.map<MetricOptionType>((equipment) => ({
            value: [
                equipment.id,
                Guid.newGuid().toString(),
                equipment.name,
            ].join("_"),
            label: `${equipment.name} Номер: ${equipment.objectId}`,
            children: equipment.equipments
                ?.map<MetricOptionType>((includedEquipment) => ({
                    value: [
                        includedEquipment.id,
                        Guid.newGuid().toString(),
                        equipment.name,
                    ].join("_"),
                    label: includedEquipment.name!,
                    children: includedEquipment.metrics?.map((metric) => ({
                        value: [
                            metric.id,
                            Guid.newGuid().toString(),
                            equipment.name,
                            translateUnitType(metric.valueUnitType),
                        ].join("_"),
                        label: `${metric.name} ${translateUnitType(
                            metric.valueUnitType
                        )}`,
                    })),
                }))
                .concat(
                    equipment.metrics?.map<MetricOptionType>((metric) => ({
                        value: [
                            metric.id,
                            Guid.newGuid().toString(),
                            equipment.name,
                            translateUnitType(metric.valueUnitType),
                        ].join("_"),
                        label: `${metric.name} ${translateUnitType(
                            metric.valueUnitType
                        )}`,
                    })) ?? []
                ),
        }));
    }

    async function onUpdate(): Promise<void> {
        const request: UpdateComponentRequest = {
            maxValue: maxValue,
            metricId: (selectedMetric!.value as string).split("_")[0],
        };

        await ComponentsService.putApiComponents(component.id, request)
            .then((component) => {
                onComponentUpdate(component);
                onEdit(false);
            })
            .catch(ErrorHandler.handle);
    }

    useEffect(() => {
        async function loadEquipments() {
            setBusy(true);

            await MetricsService.getApiMetricsEquipments(production.id)
                .then((equipments) =>
                    setEquipmentsOptions(mapEquipments(equipments))
                )
                .catch(ErrorHandler.handle)
                .finally(() => setBusy(false));
        }

        loadEquipments();
    }, [production.id]);

    return (
        <Spin spinning={isBusy}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row>
                    <Col xl={8} xs={24}>
                        <TreeSelect
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder="Метрика"
                            allowClear
                            treeDefaultExpandAll
                            labelInValue
                            showSearch
                            value={selectedMetric}
                            onChange={onMetricChange}
                            treeData={equipmentsOptions}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={8} xs={24}>
                        <Input
                            type="number"
                            placeholder="Максимальное значение"
                            value={maxValue}
                            onChange={onMaxValueChanged}
                        ></Input>
                    </Col>
                </Row>
                <Space>
                    <Button type="dashed" onClick={() => onEdit(false)}>
                        Отменить
                    </Button>
                    <Button type="primary" onClick={onUpdate}>
                        Сохранить
                    </Button>
                </Space>
            </Space>
        </Spin>
    );
};
