/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from "../models/LoginRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { AuthorizationState } from "../models/AuthorizationState";

export class AuthorizationService {
    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAuthorizationSignIn(
        requestBody?: LoginRequest
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/Authorization/SignIn",
            body: requestBody,
            mediaType: "application/json",
            errors: {
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAuthorizationRefresh(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/Authorization/Refresh",
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns AuthorizationState Success
     * @throws ApiError
     */
    public static getAuthorizationState(): CancelablePromise<AuthorizationState> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/Authorization/State",
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAuthorizationSignOut(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/Authorization/SignOut",
            errors: {
                500: `Server Error`,
            },
        });
    }
}
