import { Avatar, Button, Input, List, Space } from "antd";
import { debounce } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Locations from "../consts/Locations";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import { UserDtoPagedList, UsersService } from "../services/openapi";

export const UsersPage: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const [isBusy, setBusy] = useState<boolean>();
    const [users, setUsers] = useState<UserDtoPagedList>();
    const [searchQuery, setSearchQuery] = useState<string>();

    function onSearchQueryChanged(event: ChangeEvent<HTMLInputElement>) {
        setSearchQuery(event.target.value);
    }

    function onPageChanged(pageNumber: number) {
        setPage(pageNumber);
    }

    useEffect(() => {
        async function fetchUsers() {
            setBusy(true);

            await UsersService.getApiUsers(searchQuery, (page - 1) * 10, 10)
                .then((users) => setUsers(users))
                .catch((error) => ErrorHandler.handle(error))
                .finally(() => setBusy(false));
        }

        const debounceFetch = debounce(fetchUsers, 300);

        debounceFetch();

        return () => debounceFetch.cancel();
    }, [searchQuery, page]);

    return (
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
            <Input
                inputMode="email"
                onChange={onSearchQueryChanged}
                placeholder="Почтовый адрес пользователя"
            ></Input>

            <Button type="primary">
                <Link to={Locations.CreateUser}>Создать пользователя</Link>
            </Button>

            {users && (
                <List
                    loading={isBusy}
                    pagination={{
                        pageSize: 10,
                        current: page,
                        onChange: onPageChanged,
                    }}
                    itemLayout="horizontal"
                    dataSource={users.items}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                                    />
                                }
                                title={
                                    <Link to={`${Locations.Users}/${item.id}`}>
                                        {item.fullname}
                                    </Link>
                                }
                                description={`${item.email}`}
                            />
                        </List.Item>
                    )}
                />
            )}
        </Space>
    );
};

export default UsersPage;
