import {
    Alert,
    Button,
    Checkbox,
    DatePicker,
    Input,
    Modal,
    Space,
    Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useState } from "react";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import {
    CreateUnsafeLicenseRequest,
    UnsafeLicensesService,
} from "../services/openapi";
import { MayBe } from "../components/logic/Maybe";

export const LicensePage: React.FC = () => {
    const [identificator, setIdentificator] = useState<string>("");
    const [expireDate, setExpireDate] = useState<Dayjs | undefined | null>(
        dayjs()
    );
    const [isUnlimitedLicense, setIsUnlimitedLicense] = useState<boolean>(
        false
    );

    const onIdentificatorChanged = (event: ChangeEvent<HTMLInputElement>) =>
        setIdentificator(event.target.value);

    const onUnlimitedLicenseChanged = (event: CheckboxChangeEvent) =>
        setIsUnlimitedLicense(event.target.checked);

    const onExpiredDateChanged = (date?: Dayjs | null | undefined) =>
        setExpireDate(date);

    const onGenerateLicenseClicked = async () => {
        const request: CreateUnsafeLicenseRequest = {
            pcIdentificator: identificator,
            isUnlimited: isUnlimitedLicense,
            expireDateTime: expireDate?.toJSON(),
        };

        await UnsafeLicensesService.postApiUnsafeLicenses(request)
            .then((result) =>
                Modal.info({ title: "Лицензионный ключ", content: result })
            )
            .catch(ErrorHandler.handle);
    };

    return (
        <Space direction="vertical" style={{ width: "100%" }} size={12}>
            <Typography.Title level={5}>Лицензия</Typography.Title>
            <Typography.Text className="description-text">
                Здесь вы можете сгенерировать лицензию без созданию карточки
                производства.
            </Typography.Text>
            <Typography.Text className="description-text">
                После генерации вам будет выдан лицензионный ключ, его
                необходимо будет отправить оператору SmartMix или вставить его
                вручную.
            </Typography.Text>
            <Alert
                message="Пожалуйста, учитывайте часовой пояс производства. Время окончания лицензии указывается по времени производства"
                type="warning"
                showIcon
            />

            <Input
                placeholder="Идентификатор компьютера"
                value={identificator}
                onChange={onIdentificatorChanged}
            ></Input>

            <MayBe
                condition={() => !isUnlimitedLicense}
                content={
                    <DatePicker
                        value={expireDate}
                        onChange={onExpiredDateChanged}
                        style={{ width: "100%" }}
                        showTime
                        placeholder="Дата и время окончания лицензии"
                        format={"DD.MM.YYYY HH:00"}
                    ></DatePicker>
                }
            />

            <Checkbox
                checked={isUnlimitedLicense}
                onChange={onUnlimitedLicenseChanged}
            >
                Безлимитная лицензия
            </Checkbox>
            <Button type="primary" onClick={onGenerateLicenseClicked}>
                Сгенерировать ключ
            </Button>
        </Space>
    );
};
