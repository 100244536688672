import React, { ChangeEvent, useState } from "react";
import { FolderDto, ProductionDto, ProductionsService, UpdateFolderRequest } from "../../../services/openapi";
import { Col, Dropdown, Input, MenuProps, Modal, Space, Typography, message } from "antd";
import { ErrorHandler } from "../../../services/errors/ErrorHandler";
import { BiDotsVerticalRounded } from "react-icons/bi";

export const FolderActions: React.FC<{
    onChange(): Promise<void>,
    production: ProductionDto,
    folder: FolderDto
}> = ({ production, folder, onChange }) =>
    {
        const [name, setName] = useState(folder.name ?? '');
        const [isOpen, setOpen] = useState<boolean>(false);
        const items: MenuProps['items'] = [
            {
                key: '1',
                label: 'Изменить',
                onClick: () => setOpen(true)
            },
            {
                key: '2',
                danger: true,
                label: 'Удалить',
                onClick: onDeleteClicked
            },
        ];

        function onFileNameChanged(event: ChangeEvent<HTMLInputElement>): void
        {
            setName(event.target.value);
        }

        async function onDeleteClicked(): Promise<void>
        {
            if (!production.id) throw new Error();
            if (!folder.id) throw new Error();

            await ProductionsService
                .deleteApiProductionsFolders(
                    production.id,
                    folder.id)
                .then(async () => await onChange())
                .catch(ErrorHandler.handle);
        }

        async function onSaveClicked(): Promise<void>
        {
            if (!production.id) throw new Error();

            const request: UpdateFolderRequest = {
                name: name,
            };

            await ProductionsService
                .patchApiProductionsFolders(
                    production.id,
                    folder.id,
                    request)
                .then(async () =>
                {
                    message.success("Папка изменена");
                    await onChange();
                })
                .catch(ErrorHandler.handle)
                .finally(() => setOpen(false));
        }

        return (
            <Col span={ 1 }>
                <Dropdown menu={ { items } }>
                    <a onClick={ (e) => e.preventDefault() }>
                        <Space>
                            <BiDotsVerticalRounded />
                        </Space>
                    </a>
                </Dropdown>

                <Modal okText="Сохранить" onOk={ onSaveClicked } onCancel={ () => setOpen(false) } cancelText="Отменить" open={ isOpen } title="Редактирование документа">
                    <Space direction="vertical" style={ { width: "100%" } } size={ 16 }>
                        <Typography.Text className="description-text">Измените имя файла или описание</Typography.Text>
                        <Input value={ name } onChange={ onFileNameChanged } placeholder="Название файла"></Input>
                    </Space>
                </Modal>
            </Col>
        );
    };
