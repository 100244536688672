import { AddCommentRequest, ProductionsService } from "../../../../services/openapi";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Space, Spin, message } from "antd";
import { ErrorHandler } from "../../../../services/errors/ErrorHandler";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setProduction } from "../../../../store/productionSlice";


export const ProductionCommentEdit: React.FC<{ onClick(): void; }> = ({ onClick }) =>
{
    const production = useAppSelector(state => state.productionState.production!);
    const dispatch = useAppDispatch();

    const [isBusy, setBusy] = useState<boolean>(false);
    const [commentText, setCommentText] = useState<string>('');

    const onCommentChanged = (event: ChangeEvent<HTMLTextAreaElement>) => setCommentText(event.target.value);


    const updateProduction = async () =>
    {
        if (production)
        {
            await ProductionsService
                .getApiProductions1(production.id)
                .then(result => dispatch(setProduction(result)))
                .catch(ErrorHandler.handle);
        }
    };

    const save = async () =>
    {
        const request: AddCommentRequest = {
            text: commentText
        };

        setBusy(true);

        await ProductionsService
            .postApiProductionsComments(production.id, request)
            .then(async () => await updateProduction())
            .then(() => onClick())
            .then(() => setBusy(false))
            .then(() => message.success("Комментарий успешно изменен"))
            .catch(ErrorHandler.handle)
            .finally(() => setBusy(false));
    };

    useEffect(() =>
    {
        setCommentText(production.lastComment?.text ?? '');
    }, [production.lastComment?.text]);

    return <Spin spinning={ isBusy }>
        <Space direction="vertical" style={ { width: "100%" } } size={ 24 }>
            <TextArea
                value={ commentText }
                onChange={ onCommentChanged }
                autoSize={ { minRows: 8, maxRows: 12 } }
                placeholder="Введите текст комментария" />

            <Button
                onClick={ async () => await save() }
                style={ { margin: "0 auto", display: "absolute" } } type="primary">Отправить</Button>
        </Space>
    </Spin>;
};
