import { Button, Col, Layout, Menu, Row, Typography } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineUser } from "react-icons/ai";
import { BiExit } from "react-icons/bi";
import { BsKey } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { GiBrainTentacle } from "react-icons/gi";
import { MdOutlineFactory } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";
import Locations from "../consts/Locations";
import Roles from "../consts/Roles";
import { ErrorHandler } from "../services/errors/ErrorHandler";
import { AuthorizationService } from "../services/openapi";
import { AuthorizationState } from "../services/openapi/models/AuthorizationState";

export const ApplicationLogo: React.FC = () => {
    return (
        <span>
            <IconContext.Provider
                value={{
                    style: { color: "var(--color-title-primary)" },
                    size: "25",
                }}
            >
                <GiBrainTentacle />
            </IconContext.Provider>
        </span>
    );
};

export interface PrivateLayoutProps {
    authorizationState: AuthorizationState;
}

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({
    authorizationState,
}) => {
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleClick = () => setDrawerOpen(!isDrawerOpen);

    function isInRoles(roles: string[]): boolean {
        return authorizationState.roles.some((role) => roles.includes(role));
    }

    async function onSignOutClicked() {
        await AuthorizationService.postApiAuthorizationSignOut()
            .then(handleClick)
            .catch(ErrorHandler.handle);
    }

    return (
        <Layout style={{ backgroundColor: "#FFF" }}>
            <Header
                style={{
                    paddingLeft: 25,
                    background: "#FFF",
                    borderBottom: "1px solid #EBEBEB",
                }}
            >
                <Row>
                    <Button
                        icon={<FiMenu />}
                        style={{ marginTop: 15 }}
                        onClick={handleClick}
                    />
                    <div
                        style={{
                            textAlign: "center",
                            marginLeft: "24px",
                            marginTop: "4px",
                        }}
                    >
                        <ApplicationLogo />
                    </div>
                    <Typography.Title
                        level={5}
                        style={{
                            textAlign: "center",
                            margin: "16px 10px",
                        }}
                    >
                        SmartWeb
                    </Typography.Title>
                </Row>
            </Header>
            <Content style={{ height: "100svh", display: "flex" }}>
                <Menu
                    mode="vertical"
                    inlineCollapsed={!isDrawerOpen}
                    style={{ height: "100%", padding: 18 }}
                >
                    {isInRoles([Roles.Admin]) && (
                        <Menu.Item icon={<AiOutlineUser />}>
                            <Link to={Locations.Users}>Пользователи</Link>
                        </Menu.Item>
                    )}

                    {isInRoles([
                        Roles.Admin,
                        Roles.Developer,
                        Roles.Seller,
                    ]) && (
                        <Menu.Item icon={<MdOutlineFactory />}>
                            <Link to={Locations.Productions}>Производства</Link>
                        </Menu.Item>
                    )}

                    {isInRoles([Roles.Admin]) && (
                        <Menu.Item icon={<BsKey />} style={{}}>
                            <Link to={Locations.Licenses}>Лицензии</Link>
                        </Menu.Item>
                    )}

                    <Menu.Item danger icon={<BiExit />} style={{}}>
                        <Link to={Locations.Login} onClick={onSignOutClicked}>
                            Выйти из аккаунта
                        </Link>
                    </Menu.Item>
                </Menu>
                <Row style={{ width: "100%" }}>
                    <Col xl={2} xs={1}></Col>
                    <Col xl={20} xs={22}>
                        <div
                            style={{
                                backgroundColor:
                                    "var(--color-background-primary)",
                                width: "100%",
                                padding: 20,
                            }}
                        >
                            <Outlet />
                        </div>
                    </Col>
                    <Col xl={2} xs={1}></Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default PrivateLayout;
